@import "../../../../styles/constants/breakpoints";
@import "../../../../styles/constants/colors";
@import "../../../../styles/constants/variables";

.sectionTwoGrid {
  display: flex;
  flex-direction: column;
  padding: 0 0.625rem;
  background-color: map-get($hubColors, hub_summer_yellow);
  color: map-get($background-and-border-colors, background-dark-blue);
  font-family: $open-sans;

  @media #{$smMedia} {
    flex-direction: row;
    padding: 1.25rem;
    margin: 1.25rem 1.25rem 2rem;
  }

  .middleTextContainer {
    display: flex;
    margin: 1.25rem 1rem;
    font-family: "DM Serif Display", serif;
    font-size: 1.563rem;
    font-weight: 400;
    letter-spacing: 0.125rem;
    line-height: 1.25;
    text-align: center;

    @media #{$smMedia} {
      flex-direction: row;
      align-items: center;
      margin: 0 2.5rem 0 1.5rem;
      text-align: initial;
    }

    @media #{$mdMedia} {
      font-size: 2.2rem;
      line-height: 1.1;
    }

    @media #{$lgMedia} {
      font-size: 3rem;
    }
  }

  .middleImgContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;

    img {
      width: 48%;
      max-width: 10.625rem;
      max-height: 10.625rem;

      &:nth-of-type(2) {
        margin-left: 15px;
      }
    }

    @media #{$smMedia} {
      margin-bottom: 0;

      img {
        max-width: initial;
        max-height: initial;
      }
    }

    @media #{$mdMedia} {
      img {
        width: 15rem;
        height: 15rem;

        &:nth-of-type(2) {
          margin-left: 1.5rem;
        }
      }
    }

    @media #{$lgMedia} {
      img {
        width: 18.75rem;
        height: 18.75rem;
      }
    }
  }
}
