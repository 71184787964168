@import "../../../styles/constants/colors";
@import "../../../styles/mixins/hub/hub-button-focus";

.outerModal {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s ease-in background-color;
  visibility: hidden;

  &.open {
    z-index: 5000;
    background-color: $contrast-light-hover;
    opacity: 1;
    pointer-events: unset;
    transition: 0.2s opacity ease-in-out;
    visibility: initial;
  }

  .innerModal {
    max-width: 500px;
    background-color: $color-white;
    border-radius: 1rem;
    text-align: center;

    .modalContent {
      padding: 0 2rem 1rem;

      svg {
        height: 8rem;
        box-sizing: content-box;
        padding: 0;
      }

      p {
        font-size: 1rem;
        white-space: pre-wrap;
      }
    }

    .modalButtonBottom {
      display: flex;
      justify-content: center;
      padding: 1rem 1rem 2rem;

      > button {
        min-width: 120px;
      }
    }
  }
}
