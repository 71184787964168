@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";

.classCodeContainer {
  display: flex;
  width: 20%;
  flex-wrap: wrap;
  align-items: center;
  color: map-get($hubColors, dark-gray);

  .firstPartCode {
    color: map-get($typography-colors, sub-text);
    font-weight: 600;
  }

  @media #{$phoneMedia} {
    width: initial;
  }
}

.disabledClassCode {
  display: block;
  color: map-get($hubColors, hub_people_border);
  font-size: 13px;

  &.disabledInTile {
    margin-top: $gap-4;
  }
}

.classCodeLink {
  color: map-get($primary-colors, primary);
  font-size: 13px;

  &:hover {
    color: map-get($typography-colors, sub-text);
    text-decoration: underline;
  }
}

.classCodeIconContainer {
  position: relative;
  display: block;
  height: 100%;
  padding-bottom: 10px;
}

.classCodeWideContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.8125rem 1rem 0.8125rem 0.6875rem;
  border-top: 1px solid $color-light-grey;
  border-bottom: 1px solid $color-light-grey;

  >div {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;

    >div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
}

.classCodeInClassTile {
  display: block;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;

  >div {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;

    .classCodeContainerInside {
      width: auto;
      flex-direction: column;
      align-items: flex-start;
      justify-content: left;
      margin-top: $gap-4;
    }

    .containerInClassTile {
      display: flex;
      flex-direction: initial;
      align-items: center;
      justify-content: flex-start;
    }

    .classCodeCustomContainer {
      display: inherit;
      width: auto;
      color: black;
      white-space: nowrap;
    }

    .classCodeTextInClassTile {
      font-size: 13px;
      font-weight: normal;
    }

    .joiningCodeTextInClassTile {
      color: black;
      font-weight: normal;
    }

    .organizationCodeTextInClassTile {
      width: auto;
      color: black;
      font-weight: bold;
    }

    >div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
}

.marginJoiningCode {
  padding-left: 5px;
}

.copyClassCodeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.unsetWidth {
  width: unset;
  margin-left: 2px;
}

.largeTooltip {
  height: 30px;
  padding-right: 0;

  ul li {
    display: list-item;
    margin-left: 12px;
  }

  p {
    color: $color-text-grey;
    font-size: 14px;
  }

  span {
    right: -217px;
    left: auto;
    width: 268px;
    border: 2px solid skyblue;
    background-color: white;
    color: $color-text-grey;
    font-size: 14px;
    text-align: left;
    transform: none;

    &::after {
      right: 226px;
      left: auto;
      border-bottom-color: white;
      transform: none;
    }

    &::before {
      position: absolute;
      right: 224px;
      bottom: 100%;
      left: auto;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-width: 0 10px 10px 10px;
      border-style: solid;
      border-color: transparent transparent map-get($teacher-resource-colors, tr-primary) transparent;
      border-top: 0;
      content: "";
      transform: none;
    }
  }
}

.infoIcon {
  display: flex;
  width: 1em;
  height: 1em;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 1.2em;
}
