@use "sass:map";
@import "../../styles/constants/breakpoints";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";

tr {
  .assessmentIcon:first-child {
    width: 3.125rem;

    @media #{$phoneMedia} {
      width: 74px;
    }
  }

  &:hover {
    cursor: pointer;

    p {
      color: inherit;
    }
  }
}

.rowContainerWithIcon {
  display: flex;
  align-items: center;
}

.fallbackOupIcon {
  display: flex;
  min-width: 1.875rem;
  height: 2.56rem;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: map.get($primary, primary100);

  svg {
    z-index: 100;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    fill: map.get($base, white90);
  }

  @media #{$smMedia} {
    min-width: 2.425rem;
    height: 3.125rem;
  }
}

.assessmentProgressContainer {
  flex-direction: column;
}

.subtitleFragmentContainer {
  display: flex;
  flex-direction: column;
  padding-top: $gap-4;

  .gradebookControlsContainer {
    padding-top: $gap-4;
    padding-bottom: $gap-4;
    margin-right: -0.625rem;
    margin-left: -0.625rem;
    background-color: map.get($primary, primary0);

    &.progressContainerFixed {
      position: fixed;
      z-index: 2;
    }

    @media #{$xsMedia} {
      margin-right: 0;
      margin-left: 0;

      &.progressContainerFixed {
        padding-right: 1.25rem;
      }
    }
  }

  @media #{$smTabletMedia} {
    border-bottom: $border-width-1 solid map.get($primary, primary20);
  }

  .dataRefresherBar {
    padding-top: 0;
    padding-bottom: 1rem;

    @media #{$smTabletMedia} {
      max-width: unset;
      justify-content: space-between;
    }
  }
}

.gradebookControlsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @media #{$smTabletMedia} {
    .progressSettingsButton {
      width: 100%;
    }
  }

  @media #{$xsMedia} {
    flex-direction: column;
    gap: $gap-3;
  }
}

.pushRight {
  margin-left: auto;
}

.gradebookUiUpdates {
  .heading {
    padding-right: 0;
    padding-left: 0;

    @media #{$smTabletMedia} {
      border-bottom: $border-width-1 solid map.get($primary, primary20);
    }
  }
}
