@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints";

.actionPanel {
  position: relative;
  display: flex;
  height: 3.125rem;
  align-items: center;
  border-top: 1px solid $color-light-grey;
  margin: 0 0.5rem;

  .actionButton {
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    margin: 0 0.5rem;
    fill: map-get($hubColors, blue-color-primary);

    &:hover,
    &:focus {
      fill: map-get($secondary-dark-colors, archived-dark);
    }

    &:focus {
      border-radius: 1px;
      box-shadow: 0 0 0 2px $color-white, 0 0 0 4px map-get($primary-colors, primary);
    }
  }

  .actionButton[data-tooltip]:nth-child(1)::after {
    transform: translate(-10%);
  }
}

@media #{$phoneMedia} {
  .actionPanel {
    margin: 0;
  }
}
