@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints.scss";

.carouselContainer {
  margin: 0 auto;

  .carouselItem {
    display: flex;
    min-width: 120px;
    max-width: 160px;
    height: 137px;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      height: 137px;
      background-color: $color-white;
      object-fit: cover;
    }
  }
}
