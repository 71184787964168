@import "../../../styles/constants/colors.scss";
@import "../../../styles/constants/breakpoints";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors.scss";
@import "~@oup/shared-front-end/dist/styles/themes/base/mixins/box-shadow";

.secondaryLevelClasses {
  width: 100%;

  .secondaryLevelClass {
    display: flex;
    min-height: 3.75rem;
    align-items: center;
    padding: $gap-3 18px;
    margin-left: 3px;
    cursor: pointer;
    font-family: $font-family-text-font;
    font-weight: $font-weight-400;
    letter-spacing: $letter-spacing-0;
    line-height: $line-height-120;

    &:hover {
      background-color: map-get($primary, primary10);
      mix-blend-mode: multiply;
    }

    &:focus {
      box-shadow: $box-shadow-focus-inset();
    }

    &:hover,
    &:hover:focus {
      background-color: map-get($primary, primary10);
      mix-blend-mode: multiply;
    }

    .secondaryLevelHiddenClass {
      display: flex;
      width: 1.875rem;
      height: 1.875rem;
      align-items: center;
      justify-content: center;
      border: 1px solid map-get($primary, primary20);
      margin-right: $gap-3;
      background-color: map-get($modern, isadora-pink);
      border-radius: 100%;

      > div {
        color: map-get($primary, primary100);
        font-family: $font-family-text-font;
        font-weight: $font-weight-400;
        letter-spacing: $letter-spacing-0;
        line-height: $line-height-120;
      }
    }

    .imgContainer {
      overflow: hidden;
      width: 1.875rem;
      height: 1.875rem;

      .classImage {
        display: flex;
        overflow: hidden;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        padding: 0.4rem;
        background-color: map-get($primary, primary100);
        background-color: map-get($secondary-colors, classroom);
        border-radius: 100%;

        svg {
          width: 100%;
          height: 100%;
          fill: map-get($base, white100);
        }
      }
    }

    .textContainer {
      margin-left: $gap-3;

      .class,
      .teacher {
        overflow: hidden;
        width: 12.5rem;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media #{$phoneMedia} {
          width: calc(100vw - 6.875rem);
        }
      }

      .class:only-child {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: initial;
      }

      .teacher {
        margin-top: 4px;
        color: map-get($primary, primary100);
        font-size: $font-size-xsmall;
      }
    }
  }
}
