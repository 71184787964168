@import "../../../styles/constants/colors";
@import "../../../styles/constants/breakpoints.scss";
@import "../../../styles/mixins/hub/hub-button-focus";

.organizationSubMenu {
  min-width: 16.1rem;
  max-width: 18.75rem;
  height: 100%;
  min-height: calc(100vh - 11.8525rem); //right border expansion
  padding: 1.5rem;
  border-right: 1px solid $color-light-grey;

  li a {
    line-height: 1.5rem;
  }

  .manageOrgItem {
    border-top: 1px solid $color-light-grey;
  }

  .subMenuItem {
    position: relative;
    display: flex;
    width: 100%;
    min-height: 3.125rem;
    align-items: center;
    border-left: 5px solid $color-black-rgba;
    color: map-get($hubColors, hub_link);
    font-weight: 400;
    text-align: unset;

    .buttonName {
      position: relative;
      width: 100%;
      padding: 0.8125rem 1rem 0.8125rem 0.6875rem;
      cursor: pointer;
      text-transform: capitalize;
    }

    &:focus:not(.subMenuItemActive) {
      background-color: map-get($secondary-colors, hub_light_grey);
      border-radius: 3px;
      box-shadow: 0 0 0 1px map-get($primary-colors, primary), 0 0 0 2px $color-white;
      outline: none;
    }

    &:hover:not(.subMenuItemActive) {
      @include submenu-button-on-hover();
    }

    &.subMenuItemActive {
      border-left: 5px solid map-get($secondary-colors, hub_blue);
      color: map-get($secondary-dark-colors, archived-dark);
      font-weight: 600;

      &:focus {
        @include no-padding-button-on-focus();
      }
    }
  }

  .manageOrgIcon {
    width: 1.56rem;
    height: 1.56rem;
  }
}

@media #{$phoneMedia} {
  .organizationSubMenu {
    width: 100%;
    min-width: unset;
    max-width: unset;
    padding: unset;
    border: none;
  }
}
