@import '../../styles/constants/colors.scss';

.container {
  border: 1px solid $color-borders;
  margin-right: 0 !important;
  margin-left: 0 !important;
  border-radius: 5px;
}

.inputContainer {
  display: flex;
  flex-direction: row;
}

.mainContent {
  flex-grow: 1;
}

.verticalDividerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 980px) {
    display: none;
  }
}

.verticalDivider {
  width: 1px;
  height: 100%;
  background-color: $color-borders;
}

.header {
  color: map-get($hubColors, dark-gray);
  font-size: 21px;
  font-weight: 400;
}

.borderedCol {
  border: 1px solid $color-borders !important;
  margin-top: 20px;
  margin-right: 20px;
}

.blueHeader {
  display: inline-block;
  padding: 7px 15px;
  background: map-get($secondary-colors, support_icon);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  line-height: 24px;
}

.pad2 {
  padding: 0 16px 16px 16px;
}
