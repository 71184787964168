@use "sass:map";
@import "../../../styles/constants/colors";
@import "../../../styles/constants/breakpoints";
@import "../../../styles/mixins/hub/hub-button-focus";

.outerWizardWarningModal {
  position: fixed;
  z-index: 102;
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  padding: 0;
  margin: 0;
  background-color: transparent;
  inset: 0;
  opacity: 1;
  pointer-events: none;
  transition: 0.3s background-color, z-index ease-in-out;

  &.open {
    z-index: 99999999;
    background-color: $contrast-light-hover;
    pointer-events: unset;
    visibility: initial;
  }

  .modalContainer {
    position: fixed;
    top: -100%;
    left: 50%;
    max-width: 40rem;
    border-radius: 0.3125rem;
    background-color: $color-white;
    transform: translate(-50%, -50%);
    transition: 0.3s top linear;

    &.animationFadeIn {
      top: 50%;
    }

    @media #{$xsMedia} {
      min-width: 20rem;
    }

    .modalHeader {
      padding: 1.5rem;
      border-bottom: 1px solid map.get($background-and-border-colors, border);
      text-align: right;

      button {
        width: 5.75rem;
        min-height: 1.875rem;

        &:hover,
        &:focus {
          background-color: map.get($secondary-colors, hub_light_grey);
          color: map.get($hubColors, hub_dark_blue);
          filter: brightness(100%);

          svg {
            fill: map.get($hubColors, hub_dark_blue);
          }
        }

        &:focus {
          @include standart-focus-button;
        }
      }

      .title {
        font-size: 2rem;
        font-weight: 700;
        text-align: center;

        @media #{$xsMedia} {
          font-size: 1.5rem;
        }
      }
    }

    .modalContent {
      padding: 2rem 1.5rem;

      .options {
        margin-bottom: 1rem;
      }

      .option {
        display: block;
        margin-bottom: 1rem;
        width: 100%;
      }
    }

    .modalFooter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.5rem;
    }
  }
}
