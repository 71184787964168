.peopleContainer {
  position: relative;

  > div > :nth-child(3) > div {
    padding-top: 0.5rem;
  }

  .paginationButtonsContainer {
    margin: 1rem 0 0 0;
  }
}
