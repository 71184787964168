@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";

.showAllContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $color-panel-background;
  border-radius: 5px;

  span:first-child {
    font-size: 21px;
  }

  span:nth-child(2) {
    color: map-get($hubColors, hub_link);
    text-transform: lowercase;
  }

  &:hover,
  &:focus {
    background-color: map-get($secondary-colors, hub_light_grey);

    span:nth-child(2) {
      color: map-get($hubColors, hub_link_hover);
    }
  }

  &:focus {
    @include tile-on-focus();
  }
}

@media #{$phoneMedia} {
  .showAllContainer {
    height: 6.25rem;
  }
}
