@import "../../styles/constants/breakpoints";
$minColWidth: 220px;
$resourceMinWidth: 140px;
$maxColWidth: 1fr;

.organizationLayout {
  display: flex;
  overflow: visible;
  height: 98.5%;
  align-items: flex-start;

  @media #{$phoneMedia} {
    min-height: 70vh;
  }

  .organizationLayoutSubMenu {
    height: 100%;

    @media #{$phoneMedia} {
      width: 100%;
      padding: 12px;
      margin: 0;
    }
  }

  .organizationChildrenContainer {
    width: 100%;
    padding: 24px;

    @media #{$smMedia} {
      padding: 16px;
    }

    @media #{$smMdMedia} {
      padding: 24px;
    }

    @media #{$phoneMedia} {
      padding: 24px 12px;
    }
  }
}
