@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";
@import "~@oup/shared-front-end/dist/styles/breakpoints";

.firstLastNameStepContainer {
  width: 40.5rem;
  max-width: 100%;
  padding-top: $gap-6;

  @media (max-width: $screen-width-600) {
    padding: $gap-4;
  }

  .container {
    display: grid;
    padding: $gap-5 0;
    gap: $gap-5;

    @media (max-width: $screen-width-600) {
      padding: 0;
    }
  }
}

.ylStepContainer {
  display: flex;
  width: 100%;
  max-width: 63.5rem;
  flex-direction: row;
  justify-content: center;
  padding-top: $gap-6;
  gap: $gap-5;

  @media (max-width: $screen-width-1024) {
    padding: $gap-4;
  }

  @media (max-width: $screen-width-600) {
    height: auto;
    flex-direction: column;
    padding-top: $gap-4;
  }

  .container {
    display: flex;
    width: 60%;
    flex-direction: column;
    padding: $gap-5 0;
    gap: $gap-6;

    @media (max-width: $screen-width-600) {
      width: 100%;
      padding: 0 $gap-4;
    }
  }

  .rightColumnContainer {
    padding: $gap-5 0;

    @media (max-width: $screen-width-600) {
      width: 100%;
      padding: 0 $gap-4;
    }

    .illustrationContainer  {
      text-align: center;

      img {
        width: 22.5rem;
        height: 22.5rem;
      }
    }
  }
}

.heading {
  padding: 0;
  font-family: $font-family-display-font;
}
