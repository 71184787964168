@import "../../../styles/constants/breakpoints";
@import "../../../styles/constants/colors";

.tabsContainer {
  width: 100%;
  border-bottom: 1px solid $color-light-grey;
  margin: 0.9375rem 0 0 0;

  .tabContainer {
    display: inline-block;
    padding-right: 0.625rem;
    margin-left: 0.5rem;
  }

  .tabContainer:nth-child(1) {
    margin-left: 1.5rem;
  }

  .tabButton {
    min-height: 3.75rem;
    padding: 0.75rem 1.5rem;
    border: 1px solid $color-light-grey;
    background-color: map-get($background-and-border-colors, background);
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    color: map-get($hubColors, hub_link);
    font-size: 1.3125rem;
    outline: none;
    transform: translateY(1px);
    transition: 0.1s color ease-in-out, 0.1s background-color ease-in-out;

    @media #{$phoneMedia} {
      min-height: 3.125rem;
      padding: 0.75rem 1rem;
      font-size: 1rem;
    }

    &:hover {
      background-color: map-get($secondary-colors, hub_light_grey);
      color: map-get($hubColors, blue-color-tertiary);
    }

    &.active {
      border-bottom: 1px solid transparent;
      background-color: map-get($background-and-border-colors, background);
      color: map-get($typography-colors, text);
    }

    span {
      height: 1.25rem;
      padding: 0 0.5rem;
      margin-left: 0.5rem;
      background-color: map-get($hubColors, hub_link);
      border-radius: 0.625rem;
      color: map-get($background-and-border-colors, background);
      font-size: 0.8125rem;
      font-weight: 600;
    }

    &:hover span {
      background-color: map-get($hubColors, hub_link_hover);
      color: map-get($background-and-border-colors, background);
    }

    &.active span {
      background-color: map-get($typography-colors, text);
      color: map-get($background-and-border-colors, background);
    }
  }
}
