@use "sass:map";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";

.classCode {
  display: flex;
  flex-direction: row;
  font-size: 21px;

  .firstPartCode {
    font-weight: $font-weight-700
  }

  .customClassCode button {
    border-radius: $border-radius-default;
  }
}
