
.assessmentHomeHeader {
  font-size: 1.2rem;
  font-weight: 900;
}

.assessmentHomeFolders {
  margin-top: 1rem;
}

.assessmentHomeFolderContainer {
  position: relative;
  display: block;
  width: 100%;

  >div {
    position: relative;
    margin-right: 1rem;
    float: left;
  }
}
