@use "sass:map";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";

.stepContainer {
  display: flex;
  max-width: 40.5rem;
  min-height: calc(100vh - 20rem);
  flex: 1 0 0;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: $gap-6 $gap-5;
  color: #002147;
  gap: $gap-6
}

.heading {
  padding: 0;
  font-family: $font-family-display-font;
}

.textInputContainer {
  align-self: stretch;
}
