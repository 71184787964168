@import "../../styles/constants/breakpoints.scss";
@import "../../styles/constants/colors";
@import "../../styles/constants/variables";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";

.welcomePageExplanationContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 3rem 6rem 3rem 6rem;
  color: map-get($background-and-border-colors, background-dark-blue);

  @media #{$xsMedia} {
    flex-direction: column;
    align-items: center;
    padding: 1rem 2rem 1rem 2rem;
  }

  .welcomePageImageContainer {
    display: flex;
    width: 20%;
    max-width: 185px;

    @media #{$xsMedia} {
      width: 100%;
    }
  }

  .welcomePageTextContainer {
    display: flex;
    width: 80%;
    max-width: 750px;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    padding: 3rem;

    @media #{$xsMedia} {
      width: 100%;
      padding: 1rem;
      font-size: 0.6rem;
    }
  }
}

.welcomePageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hubTopContentContainerText {
  font-family: $font-family-text-font;
  font-size: 2rem;
  font-weight: 900;

  @media #{$xsMedia} {
    font-size: 1.2rem;
  }

  @media #{$smMedia} {
    font-size: 1.4rem;
  }
}

.welcomePageWideBanner {
  width: 100%;
  max-width: unset;
}

.welcomePageTextExplanationContainer {
  box-sizing: border-box;
  margin-top: 0.75rem;
  font-size: 1.2rem;

  @media #{$xsMedia} {
    font-size: 1rem;
    text-align: center;
  }
}

.welcomePageButtonContainer {
  @media #{$xsMedia} {
    display: flex;
    justify-content: center;
  }
}

.welcomePageTextSubtitleContainer {
  font-size: 1.6rem;
  font-weight: 900;

  @media #{$xsMedia} {
    font-size: 1.2rem;
    text-align: center;
  }
}

.boldedText {
  margin-right: 0.35rem;
  margin-left: 0.35rem;
  font-weight: 900;
}
