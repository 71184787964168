@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints";
@import "../../styles/mixins/hub/hub-button-focus";

.rowHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.75rem 0.75rem;
  margin: 0.75rem 0 0 0;

  .textContainer {
    span {
      color: map-get($secondary-dark-colors, archived-dark);
      font-size: 1rem;
      font-weight: 600;

      @media #{$phoneMedia} {
        font-size: 0.875rem;
      }
    }
  }

  .textContainer:first-child {
    display: flex;
    width: 60%;
    align-items: center;

    svg {
      border: 1px solid $color-light-grey;
      margin: 0 0.75rem 0 0;
      fill: $color-white;
    }

    @media #{$phoneMedia} {
      width: initial;
    }
  }

  .textContainer:nth-child(2) {
    text-align: left;

    @media #{$xsMedia} {
      display: none;
    }
  }

  .alignItemsCentered {
    display: flex;
    align-items: center;
  }

  .largeTooltip {
    display: initial;
    height: 30px;
    padding-right: 13px;

    ul li {
      display: list-item;
      margin-left: 12px;
    }

    p {
      color: $color-text-grey;
      font-size: 14px;
    }

    span {
      right: auto;
      left: 0;
      width: 268px;
      border: 2px solid skyblue;
      background-color: $color-white;
      color: $color-text-grey;
      font-size: 14px;
      text-align: left;
      transform: none;

      &::after {
        right: auto;
        left: 11px;
        border-bottom-color: $color-white;
        transform: none;
      }

      &::before {
        position: absolute;
        right: 50%;
        bottom: 100%;
        left: 18px;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-width: 0 10px 10px 10px;
        border-style: solid;
        border-color: transparent transparent skyblue transparent;
        border-top: 0;
        content: "";
        transform: translateX(-50%);
      }
    }

    .infoIcon {
      display: flex;
      width: 1em;
      height: 1em;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      margin-left: 10px;
      font-size: 1.2em;

      button:focus {
        @include no-padding-button-on-focus() ;
      }
    }
  }

  .buttonContainer {
    @media #{$phoneMedia} {
      max-width: 35%;
    }
  }
}

.headerWithFourColumns {
  justify-content: flex-start;

  .textContainer:first-child {
    width: 45%;
  }

  .textContainer:nth-child(2),
  .textContainer:nth-child(3) {
    width: 20%;
  }

  .textContainer:nth-child(2) button {
    width: 3rem;
  }
}
