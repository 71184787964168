@import "../../../styles/constants/colors";
@import "../../../styles/constants/breakpoints";

.personProfilePage {
  .userInfoDot {
    display: flex;
    width: 1.6rem;
    height: 1.6rem;
    align-items: center;
    justify-content: center;
    padding: 1.6rem;
    border-radius: 50%;
    color: $color-white;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;

    @media #{$phoneMedia} {
      width: 1.3rem;
      height: 1.3rem;
      padding: 1.3rem;
    }
  }

  .student {
    background-color: map-get($secondary-colors, learner);
    color: map-get($typography-colors, text);
  }

  .teacher {
    background-color: map-get($secondary-colors, classroom);
  }

  .teacherAdmin {
    background-color: map-get($secondary-colors, classroom);
  }

  .orgAdmin {
    background-color: map-get($secondary-colors, organization);
  }

  .svgContainer svg {
    width: 1.6rem;
    margin-right: 0.75rem;
  }

  .orgDetails {
    display: flex;
    margin-top: 0.5rem;

    span {
      &.active {
        color: map-get($hubColors, hub_active_licence);
      }
    }
  }

  .userInfoContainer {
    display: flex;
    flex-direction: column;
    margin-left: 0.75rem;
  }

  .userDetails {
    display: flex;
    flex-direction: column;

    span:first-child {
      display: block;
      color: $color-black;
      font-size: 1.5265rem;
      font-weight: 700;
    }

    span:last-child {
      color: map-get($hubColors, hub_people_border);
      font-size: 1rem;
    }
  }

  .userDetailsCard {
    display: flex;
  }

  .userMainContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  .userRightContainer {
    .dropDownMenu {
      position: relative;

      button {
        box-shadow: none;
      }
    }
  }

  .border {
    width: 100%;
    height: 1px;
    margin: 0.75rem 0;
    background-color: $color-light-grey;
  }

  .closeButton {
    svg {
      width: 0.8rem;
      fill: map-get($map: $primary-colors, $key:primary);
    }
  }

  .backButton {
    svg {
      width: 0.8rem;
    }
  }
}
