@import "../../../../styles/constants/breakpoints.scss";
@import "../../../../styles/constants/colors.scss";
@import "../../../../styles/constants/variables";

.carouselRowContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0.625rem;
  margin: 0;
  background-color: map-get($hubColors, hub_summer_yellow);
  color: map-get($background-and-border-colors, background-dark-blue);
  font-family: $open-sans;

  .titleSection {
    display: flex;
    margin: 1rem;
    font-family: "DM Serif Display", serif;
    font-size: 1.563rem;
    font-weight: 400;
    letter-spacing: 0.05rem;
    line-height: 1.25;
    text-align: center;

    @media #{$mdMedia} {
      margin: 1.25rem;
      font-size: 2.2rem;
      letter-spacing: 0.125rem;
      line-height: 1.1;
    }

    @media #{$lgMedia} {
      font-size: 3rem;
    }
  }

  .carouselSection {
    margin: 1rem 0 1.5rem 0;

    @media #{$mdMedia} {
      margin: 2rem 0;
    }
  }

  .actionSection {
    margin: 0;

    .signInButton {
      height: 4.5rem;
      padding: 1.5rem;
      margin-bottom: 1rem;
      background-clip: padding-box;
      background-color: map-get($hubColors, hub_madrid_orange);
      border-radius: unset;
      color: map-get($background-and-border-colors, background-dark-blue);
      font-size: 1.25rem;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 1.5rem;

      @media #{$mdMedia} {
        height: 4.5rem;
        margin-bottom: 2rem;
        font-size: 1.563rem;
        line-height: 1.25rem;
      }

      &:hover {
        background-color: map-get($hubColors, hub_madrid_orange_dark);
        box-shadow: none;
      }

      &:focus {
        border-color: map-get($primary-colors, primary);
        box-shadow:
          0 0 0 1px $color-white,
          inset 0 0 0 2px map-get($primary-colors, primary),
          inset 0 0 0 3px $color-white;
      }

      svg {
        display: none;
      }
    }
  }

  .bottomSection {
    padding: 1rem;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1.5;
    text-align: center;

    @media #{$smMedia} {
      font-size: 1.25rem;
      line-height: 1.25;
    }

    @media #{$mdMedia} {
      font-size: 1.563rem;
      line-height: 1.25;
    }

    .signInLink {
      text-decoration: underline;
    }
  }
}
