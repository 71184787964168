@import "../../../styles/constants/breakpoints";
@import "../../../styles/constants/colors";
@import "../../../styles/mixins/hub/hub-button-focus";

.classwork {
  .classworkButtons {
    margin-bottom: 1rem;

    button {
      min-height: 2.5rem;
      padding: 0.5rem 0.75rem;
      border: 1px solid map-get($background-and-border-colors, border);

      &:first-child {
        border-right: none;
        border-bottom-left-radius: 1.5625rem;
        border-top-left-radius: 1.5625rem;
      }

      &:last-child {
        border-left: none;
        border-bottom-right-radius: 1.5625rem;
        border-top-right-radius: 1.5625rem;

        svg {
          width: 1rem;
          height: 1rem;
          margin: 0 0.75rem 0 0;
          fill: map-get($hubColors, hub_expiring_licence);
          transform: translateY(20%);
        }
      }

      &.active {
        border: 1px solid transparent;
        background-color: map-get($primary-colors, primary);
        color: $color-white;

        svg {
          fill: $color-white;
        }
      }

      &:focus {
        @include standart-focus-button();

        outline: none;
      }
    }
  }
}
