@import "../../../styles/constants/colors";
@import "../../../styles/constants/breakpoints";

.orgClassesContainer {
  position: relative;

  .paginationButtonsContainer {
    margin: 1rem 0 0 0;
  }

  .rowContainer {
    border-top: 1px solid map-get($hubColors, hub_people_border);
  }

  .widgetContainer {
    position: absolute;
    z-index: 21;
    top: -2.95rem;
    left: 3rem;
    display: flex;
    align-items: center;
    padding: 0.75rem;
    background-color: $color-white;
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    box-shadow: 0 0 10px $color-black4;
    clip-path: inset(-10px -10px 0 -10px);

    &::after {
      position: absolute;
      z-index: 22;
      bottom: -2.8rem;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $color-white;
      content: "";
    }

    span:first-child {
      display: flex;
      width: 1.5rem;
      height: 1.5rem;
      align-items: center;
      justify-content: center;
      padding: 0.325rem;
      margin-right: 0.75rem;
      background-color: map-get($primary-colors, primary);
      border-radius: 100%;
      color: $color-white;
    }

    span:last-child {
      color: map-get($primary-colors, primary);
    }
  }

  .buttonContainer {
    display: flex;
    width: 12rem;
    align-items: center;
    text-decoration: underline;


    button {
      border: none;
      box-shadow: none;
      outline: none;
      transform: none;
      font-weight: bold;

      @media #{$phoneMedia} {
        font-size: 90%;
      }

      a:focus {
        border: none;
        box-shadow: none;
        outline: none;
        transform: none;
      }
    }

    div + div {
      button {
        @media #{$phoneMedia} {
          padding-left: 0.25rem;
        }
      }
    }

    button:not(:first-child) {
      margin-left: 0.5rem;
    }

    svg {
      display: none;
    }
  }
}
