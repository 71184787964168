@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints";

.roleTile {
  position: relative;
  display: flex;
  width: 17rem;
  height: 15.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.3125rem;
  cursor: pointer;
  text-align: center;

  &:not(:last-child) {
    margin-right: 1.5rem;

    @media #{$phoneMedia} {
      margin: 0 0 1.5rem 0;
    }
  }

  &::before {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0.0625rem solid $color-light-grey;
    border-radius: 0.3125rem;
    content: "";
  }

  &:hover {
    &::before {
      border: 0.125rem solid map-get($hubColors, blue-color-primary);
    }
  }

  :focus {
    border-radius: 0.3125rem;
    box-shadow: 0 0 0 0.0625rem $color-white, 0 0 0 3px map-get($primary-colors, primary), inset 0 0 0 3px $color-white;
    outline: none;
  }

  a {
    display: block;
    width: 100%;
    padding: 0.75rem;
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 12.5rem;
    height: 12.5rem;
  }

  .textContent span {
    font-weight: 600;
  }

  &.wizardMode {
    width: 100%;
    @media #{$phoneMedia} {
      width: 17rem;
      height: 14rem;
      ul {
        align-items: center;
        justify-content: center;
        gap: 0rem;
      }
    }
    &::before {
      border-radius: 0.75rem;
    }
    &:hover {
      &::before  {
        background-color: rgba(229, 233, 237, 1);
        border: 0.1875rem solid map-get($hubColors, blue-color-dark);
      }
    }
    :focus {
      box-shadow: none;
    }
  }
  &.selected {
    background-color: rgba(229, 233, 237, 1);
    border: 0.1875rem solid map-get($hubColors, blue-color-dark);
    border-radius: 0.75rem;
  }
}
