@use "sass:map";
@import "../../styles/constants/breakpoints";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";

.dashboardLayout {
  display: flex;
  min-height: 650px;
  font-family: $font-family-text-font;
  font-weight: 400;
  line-height: 1.5;

  a {
    font-family: $font-family-text-font;
  }
}

.neutralBlue {
  background-color: map.get($neutral, neutral-blue100);
}

.kipperYellow {
  background-color: map.get($modern, kipper-yellow);
}

.dashboardContent {
  overflow: hidden;
  width: 100%;

  @media #{$smMedia} {
    margin-left: 4.375rem;
  }

  transition: margin-left 0.3s ease-in-out;

  &.navOpen {
    @media #{$lgMedia} {
      max-width: 85vw;
      margin-left: 18rem;
    }
  }

  .pageHeroContainer {
    padding: $gap-5;
  }
}

@media #{$phoneMedia} {
  .dashboardContent {
    margin: 0;
  }
}
