@use "sass:map";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors.scss";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes.scss";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography.scss";

.stepContainer {
  display: flex;
  max-width: 40.5rem;
  flex-direction: column;
  padding: $gap-6 $gap-5;
  gap: $gap-6;
}

.heading {
  font-family: $font-family-display-font;
  padding: 0;
}

.errorContainer {
  display: flex;
  margin-left: 0;
}

.errorGlyph {
  min-width: 1rem;
  height: 1.25rem;
  min-height: 1.25rem;
  max-height: 1.25rem;
  margin-top: 0.125rem;
  margin-right: 0.5rem;
  fill: map.get($base, white100);
}
