@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";

.entityPageHeader {
  display: flex;
  flex-direction: column;
  background: map-get($neutral, neutral-blue100);
  max-width: 100vw;
  text-wrap: balance;

  .headingAndButton {
    align-items: center;
    color: map-get($primary, primary100);
    padding-right: 3rem;
    font-family: $font-family-display-font;
    font-size: $font-size-medium;
    font-weight: $font-weight-400;
    margin-top: 0.75rem;
    margin-bottom: 2.25rem;
    h1 {
      margin: 0 1rem 0 0;
      margin-bottom: 0.75rem;
      font-size: $font-size-2xlarge;
    }

    a {
      margin-right: 1rem;
      color: map-get($primary, primary100);
    }
  }

  p {
    line-height: $line-height-120;
    margin-bottom: 1.5rem;
  }
}
