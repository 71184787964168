@import "../../styles/constants/breakpoints.scss";
@import "../../styles/constants/colors";
@import "../../styles/constants/variables";

.welcomePageActionContainer {
  display: flex;
  width: calc(100% - 8rem);
  max-width: 935px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: space-between;
  padding: 1rem;
  border-width: 1px;
  border-style: solid;
  border-color: map-get($background-and-border-colors, border);
  margin: 2rem 4rem 4rem 4rem;
  border-radius: 4px;

  @media #{$xsMedia} {
    width: calc(100% - 4rem);
    margin: 1rem 2rem 2rem 2rem;
  }

  :not(button) {
    color: map-get($background-and-border-colors, background-dark-blue);
  }

  .welcomePageActionButton {
    height: 3rem;
    max-height: 3rem;
    padding: 1rem;
    margin-top: 1rem;
    background-clip: padding-box;
    background-color: map-get($hubColors, hub_madrid_orange);
    color: map-get($background-and-border-colors, background-dark-blue);
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1rem;

    @media #{$mdMedia} {
      height: 3rem;
      font-size: 1.1rem;
      line-height: 1rem;
    }

    &:hover {
      background-color: map-get($hubColors, hub_madrid_orange_dark);
      box-shadow: none;
    }

    &:focus {
      border-color: map-get($primary-colors, primary);
      box-shadow:
        0 0 0 1px $color-white,
        inset 0 0 0 2px map-get($primary-colors, primary),
        inset 0 0 0 3px $color-white;
    }
  }
}
