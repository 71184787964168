@import "~@oup/shared-front-end/dist/styles/themes/base/typography";

.onlineTestDateTimeContainer {
  input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 3rem;
  }

  h3 {
    font-weight: $font-weight_700;
  }
}
