@use "sass:map";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";

.container {
  color: map.get($primary, primary100);

  .top {
    padding-top: $gap-4;
    padding-right: $gap-4;

    button {
      margin-left: auto;
    }
  }

  .errorBox {
    text-align: center;
  }
}
