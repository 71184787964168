@import "../../../styles/constants/colors";
@import "../../../styles/constants/breakpoints";

.container {
  position: fixed;
  z-index: 20;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem 3rem;
  background-color: $color-white;
  box-shadow: 0 0 10px $color-black4;
  clip-path: inset(-60px -60px -60px 0);
  transform: translateY(8rem);
  transition: transform 0.2s ease-in-out;

  @media #{$phoneMedia} {
    padding: 1rem 0;
  }

  .widgetContainer {
    position: absolute;
    z-index: 21;
    top: -2.95rem;
    left: 3rem;
    display: flex;
    align-items: center;
    padding: 0.75rem;
    background-color: $color-white;
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    box-shadow: 0 0 10px $color-black4;
    clip-path: inset(-10px -10px 0 -10px);

    &::after {
      position: absolute;
      z-index: 22;
      bottom: -2.8rem;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $color-white;
      content: "";
    }

    span:first-child {
      display: flex;
      width: 1.5rem;
      height: 1.5rem;
      align-items: center;
      justify-content: center;
      padding: 0.325rem;
      margin-right: 0.75rem;
      background-color: map-get($primary-colors, primary);
      border-radius: 100%;
      color: $color-white;
    }

    span:last-child {
      color: map-get($primary-colors, primary);
    }
  }

  .buttonStackedContainer,
  .buttonContainer {
    display: flex;
    width: 20rem;
    align-items: center;

    button {
      border: none;
      box-shadow: none;
      outline: none;
      transform: none;

      @media #{$phoneMedia} {
        font-size: 90%;
      }

      a:focus {
        border: none;
        box-shadow: none;
        outline: none;
        transform: none;
      }
    }

    button:not(:first-child) {
      margin-left: 1rem;
    }

    svg {
      display: none;
    }
  }
}

.buttonStackedContainer {
  flex-wrap: wrap;
}

.container.open {
  transform: translateY(0);
}
