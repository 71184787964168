@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints";
@import "../../styles/mixins/hub/hub-button-focus";

@keyframes stretch {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

.outerPreviewInfoModal {
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  background-color: transparent;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s opacity ease-in-out;

  &.open {
    z-index: 5000;
    background-color: $contrast-light-hover;
    opacity: 1;
    pointer-events: unset;
    transition: 0.2s opacity ease-in-out;
    visibility: initial;
  }

  .modalContainer {
    padding: 1.5rem 0;
    margin: 1rem;
    background-color: $color-white;
    border-radius: 0.3125rem;

    .modalHeader {
      padding: 0 1.5rem 0 0;
      text-align: right;

      button {
        width: 5.75rem;
        min-height: 1.875rem;

        &:hover,
        &:focus {
          background-color: map-get($secondary-colors, hub_light_grey);
          color: map-get($hubColors, hub_dark_blue);
          filter: brightness(100%);

          svg {
            fill: map-get($hubColors, hub_dark_blue);
          }
        }

        &:focus {
          @include standart-focus-button();
        }
      }
    }

    .modalFooter {
      display: flex;
      justify-content: space-between;
      padding: 1rem 1.5rem 0 0;
      border-top: 1px solid map-get($secondary-colors, hub_light_grey);

      button:focus {
        @include standart-focus-button();
      }

      button:first-of-type {
        padding: 0 1.5rem;
        color: map-get($hubColors, hub_link);

        &:hover {
          color: map-get($hubColors, hub_dark_blue);
        }
      }

      button:last-of-type {
        border-bottom: 0;
      }
    }

    .contentContainer {
      width: 36rem;
      padding: 0 1.5rem;

      @media #{$xsMedia} {
        width: 100%;
        margin-top: 0.625rem;
      }

      .topInfo {
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;

        .imageContainer {
          display: flex;
          width: 5rem;
          height: 6.25rem;
          align-items: center;
          justify-content: center;
          background-color: color(primary-dark);
          border-radius: 5px;

          svg {
            width: 60%;
            height: 70%;
            fill: $color-white;
            opacity: 0.5;
          }
        }

        .textContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 1.5rem;
        }

        .firstInfoRow {
          display: flex;
          flex-direction: row;
          align-items: center;

          .testTitle {
            margin-left: 0.625rem;
            font-size: 1.5625rem;
            font-weight: 600;
          }

          .statusContainer {
            display: flex;
            height: 1.5rem;
            padding: 0 0.625rem;
            border: 1px solid;
            border-radius: 11px;
            font-size: 1rem;
            font-weight: 600;
            text-transform: uppercase;
          }

          .active {
            border-color: map-get($hubColors, blue-color-primary);

            .pulse {
              display: block;
              width: 14px;
              height: 14px;
              margin-top: 5px;
              margin-right: 6px;
              animation-delay: 0;
              animation-duration: 1.5s;
              animation-iteration-count: infinite;
              animation-name: stretch;
              animation-play-state: running;
              animation-timing-function: ease-out;
              background-color: map-get($hubColors, blue-color-primary);
              border-radius: 100%;
            }
          }

          .upcoming {
            // stylelint-disable value-keyword-case
            border-color: map-get($legacy-color-names, OK);

            .pulse {
              display: none;
            }
          }

          .completed {
            border-color: map-get($legacy-color-names, good);

            .pulse {
              display: none;
            }
          }

          @media #{$xsMedia} {
            flex-direction: column;
            align-items: flex-start;

            .testTitle {
              margin-left: 0;
            }
          }
        }

        .secondInfoRow {
          color: map-get($hubColors, dark-gray);
          font-size: 1.3125rem;

          @media #{$xsMedia} {
            font-size: 1rem;
          }
        }
      }

      .dateContainer {
        min-height: 10rem;

        p {
          padding: 0.5rem 0;
          border-top: 1px solid map-get($secondary-colors, hub_light_grey);
          margin: 0;
          font-size: 1rem;

          span:first-child {
            font-weight: 600;
          }
        }
      }
    }
  }
}
