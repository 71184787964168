@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";

.dashboard {
  display: flex;
  width: 100%;
  font-family: $font-family-text-font;
  font-weight: 400;
  line-height: 1.5;
}

.dashboardMain {
  width: 100%;
}

.dashboardFeatureFlagContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1.5rem 0;

  div {
    padding: 0;
  }

  h2 {
    margin: 0;
    font-size: 1.563rem;
    font-weight: 600;
    line-height: 1.28;
  }
}

.dashboardMiddle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5rem 1.5rem 0;

  h2 {
    margin: 0;
    font-size: 1.563rem;
    font-weight: 600;
    line-height: 1.28;
  }

  div {
    position: relative;
    min-width: 3rem;
    min-height: 2.5rem;
    padding: 0.5 1rem;
  }
}

// To be deleted in future sprints
.carrousel {
  display: flex;
  width: 100%;
  height: 21.875rem;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  background-color: $color-button-background3;
  opacity: 1;

  span {
    font-size: 1.312rem;
  }
}

@media #{$phoneMedia} {
  .dashboardMiddle {
    padding: 1.5rem 1rem 0;

    .openRedeemModalButton {
      padding: 0;
    }
  }
}
