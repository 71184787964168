@use "sass:map";
@import "../../../styles/constants/colors";

.toolsContainer {
  position: relative;
  padding-bottom: 5rem;

  .paginationButtonsContainer {
    margin: 1rem 0 0;
  }

  .rowContainer {
    border-top: 1px solid map.get($hubColors, hub_people_border);
  }

  div[class^='HubEmptyState'] {
    p span {
      display: block;
    }
  }
}
