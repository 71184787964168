.setNumberOfPlacesContent {
  padding: 1rem;
}

.setNumberOfPlacesResponseContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap: 0.5rem;
}

.infoSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}
