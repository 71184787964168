@import "@oup/shared-front-end/dist/styles/themes/base/typography.scss";
@import "@oup/shared-front-end/dist/styles/themes/base/sizes.scss";

.stepContainer {
  display: flex;
  flex-direction: column;
  padding: $gap-6 $gap-5 $gap-6 $gap-5;
  gap: $gap-5;
  max-width: 40.5rem;
}

.heading {
  font-family: $font-family-display-font;
  padding: 0;
}
