@import "../../../../styles/constants/colors.scss";

.selectInputs {
  .selectInputsContainer {
    display: flex;
    align-items: flex-end;
    width: 100%;
    gap: 1rem;
  }

  .defaultLabel {
    display: block;
    padding-top: 0.313rem;
    font-weight: 700;
  }

  .labelThinWeight {
    font-weight: 400;
  }
}

.labelLeft {
  display: flex;
  label {
    margin-right: 0.438rem;
  }
}
