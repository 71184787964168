@import "../../../styles/constants/colors";
@import "../../../styles/constants/breakpoints.scss";
@import "../../../styles/mixins/hub/hub-button-focus";

.outerWizardWarningModal {
  position: fixed;
  z-index: 102;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  padding: 0;
  margin: 0;
  background-color: transparent;
  opacity: 1;
  pointer-events: none;
  transition: 0.3s background-color, z-index ease-in-out;

  &.open {
    z-index: 99999999;
    background-color: $contrast-light-hover;
    pointer-events: unset;
    visibility: initial;
  }

  .modalContainer {
    position: absolute;
    top: -30%;
    left: 50%;
    max-width: 40rem;
    background-color: $color-white;
    border-radius: 0.3125rem;
    transform: translate(-50%, -50%);
    transition: 0.3s top linear;

    &.animationFadeIn {
      top: 35%;
    }

    @media #{$xsMedia} {
      min-width: 20rem;
    }

    .modalHeader {
      padding: 1.5rem;
      text-align: right;

      button {
        width: 5.75rem;
        min-height: 1.875rem;

        &:hover,
        &:focus {
          background-color: map-get($secondary-colors, hub_light_grey);
          color: map-get($hubColors, hub_dark_blue);
          filter: brightness(100%);

          svg {
            fill: map-get($hubColors, hub_dark_blue);
          }
        }

        &:focus {
          @include standart-focus-button();
        }
      }
    }

    .modalContent {
      text-align: center;

      .title {
        padding: 0 1.5rem 1.5rem;
        font-size: 2rem;
        font-weight: 900;

        @media #{$xsMedia} {
          font-size: 1.5rem;
        }
      }

      .subtitle {
        padding: 1.5rem;
        border-top: 1px solid map-get($secondary-colors, hub_light_grey);

        span {
          font-weight: 900;
          text-transform: capitalize;
        }
      }
    }

    .modalFooter {
      display: flex;
      justify-content: space-between;
      padding: 1.5rem;

      button:focus {
        @include standart-focus-button();
      }

      button:first-of-type {
        padding: 0;
        color: map-get($hubColors, hub_link);

        &:hover {
          color: map-get($hubColors, hub_dark_blue);
        }
      }

      button:last-of-type {
        border-bottom: 0;
      }
    }
  }
}
