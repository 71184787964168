@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";
@import "~@oup/shared-front-end/dist/styles/breakpoints";

.stepContainer {
  display: flex;
  width: 40.5rem;
  max-width: 40.5rem;
  height: 29rem;
  flex-direction: column;
  margin-top: $gap-6;
  gap: $gap-6;

  .heading {
    padding: 0;
    font-family: $font-family-display-font;
  }

  .subHeading {
    padding: $gap-4 $gap-0 $gap-0;
    font-size: $font-size-large;
    font-weight: $font-weight-600;
  }

  ul {
    padding-left: 2.5rem;
  }

  li {
    display: list-item;
    font-size: $gap-4;
  }
}

.ylStepContainer .subHeading {
  font-weight: $font-weight-400;
}
