@import "~@oup/shared-front-end/dist/styles/breakpoints";

$one-px: 0.063rem;

hr.separator {
  margin: 2.5rem 0;
}

.dataRefresherContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: ($screen-width-840 - $one-px)) {
    flex-direction: column;
  }

  a {
    align-self: center;

    @media screen and (max-width: ($screen-width-840 - $one-px)) {
      align-self: start;
      padding: 0 0 1rem 0.5rem;
    }
  }
}
