@import "../../../styles/constants/colors";
@import "../../../styles/constants/breakpoints";
@import "../../../styles/mixins/hub/hub-button-focus";

.dateText {
  margin-left: 0.25rem;
  color: map-get($hubColors, hub_active_licence);
  font-size: 1rem;
}

.dateRow {
  display: flex;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.dateCheckIcon {
  display: flex;
  height: 25px;
}

.dateExpiresText {
  margin-left: 0.5rem;
  color: map-get($typography-colors, sub-text);
  font-size: 1rem;
}

.simpleEllipsis {
  box-shadow: none;
}

.itemContainer {
  position: relative;
  display: flex;
  width: 100%;
  height: 4rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  margin: 0.75rem 0;
  border-radius: 0.3125rem;
  color: $color-black;
  cursor: pointer;

  &::before {
    position: absolute;
    z-index: -1;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    border: 1px solid $color-light-grey;
    border-radius: 0.3125rem;
    content: "";
  }

  .itemSubContainer {
    display: flex;
    align-items: center;
  }

  .itemDownload {
    color: map-get($primary-colors, primary);
  }

  .itemTitle {
    font-weight: 900;
  }

  .itemDocumentIcon {
    display: flex;
    overflow: visible;
    width: 1.875rem;
    height: 1.875rem;
    align-items: center;
    justify-content: center;
    padding: 0.375rem;
    margin-right: 0.75rem;
    background-color: $color-grey;
    border-radius: 50%;
    fill: $color-white;
  }

  &:hover {
    text-decoration: none;

    &::before {
      border: 2px solid map-get($secondary-colors, hub_blue);
    }

    .itemDocumentIcon {
      background-color: map-get($secondary-colors, hub_blue);
    }
  }

  &:focus {
    text-decoration: none;

    @include resource-on-focus();

    .itemDocumentIcon {
      background-color: map-get($primary-colors, primary);
    }
  }
}
