@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/breakpoints";

.hubPageLayout.navFixed {
  .pageContent {
    margin-top: 73px;
  }

  .secondaryNavigationContainer {
    position: fixed;
    z-index: 2;
    top: 75px;
    right: 0.93rem;
    left: 0;
  }

  .stickyContainer {
    position: fixed;
    z-index: 2;
    top: 75px;
    right: 0;
    left: 0;
  }
}

.stickyContainer {
  border-bottom: $border-width-1 solid #ccd3da;
  background-color: #fff;
}

.pageContent {
  max-width: 64rem;
  margin: 0 auto;

  &.allowContentFullWidth {
    max-width: 100%;
  }
}

@media (min-width: $screen-width-320) {
  .hubPageLayout {
    position: relative;

    .pageHeroContainer {
      padding: $gap-3 $gap-3 0 $gap-3;
    }

    .pageContent {
      padding: $gap-3;
    }

    .stickyContainer {
      padding: $gap-3;
    }

    .stickyContainerJoinClass {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.3125rem;
      font-weight: bold;

    }

    .navFixed {
      .pageContent {
        padding-top: 85px;
      }
    }
  }
}

@media (min-width: $screen-width-480) {
  .hubPageLayout {
    .pageHeroContainer {
      padding: $gap-3 $gap-4 0 $gap-4;
    }

    .pageContent {
      padding: $gap-3 $gap-4;
    }

    .stickyContainer {
      padding: $gap-3 $gap-4;
    }

    .navFixed {
      .pageContent {
        padding-top: 85px;
      }
    }
  }
}

@media (width >=48rem) {
  .hubPageLayout.navFixed {
    .secondaryNavigationContainer {
      left: 4.375rem;
    }

    .stickyContainer {
      left: 4.375rem;
    }
  }
}

@media (min-width: $screen-width-840) {
  .hubPageLayout {
    .pageHeroContainer {
      padding: $gap-3 $gap-5 0 $gap-5;
    }

    .pageContent {
      padding: $gap-3 $gap-5;
    }

    .secondaryNavigationContainer>nav {
      padding: 0 $gap-5;
    }

    .stickyContainer {
      padding: $gap-3 $gap-5;
    }
  }
}

@media (width >=1180px) {
  .hubPageLayout.navFixed.sideNavOpen {
    .secondaryNavigationContainer {
      left: 18rem;
    }

    .stickyContainer {
      left: 18rem;
    }
  }
}

@media (min-width: $screen-width-1600) {
  .hubPageLayout {
    .pageHeroContainer {
      padding: $gap-3 $gap-6 0 $gap-6;
    }

    .stickyContainer {
      padding: $gap-3 $gap-6;
    }

    .pageContent {
      padding: $gap-3 0;

      &.allowContentFullWidth {
        padding: $gap-3 $gap-6;
      }
    }

    .secondaryNavigationContainer>nav {
      padding: 0 $gap-6;
    }
  }
}
