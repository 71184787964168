@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/mixins/box-shadow";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";

.optionBox {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: $gap-1;
  min-height: $gap-6;
  flex-grow: 1;
  fill: map-get($primary, primary100);

  vertical-align: middle;
  cursor: pointer;

  border-radius: $border-radius_default;
  border: $border-width-1 solid map-get($primary, primary20);
  padding: calc($gap-4 - $border-width-1);

  transition-duration: 0.2s;
  transition-property: box-shadow, background-color;
  transition-timing-function: ease-out;
  word-break: break-all;

    &:hover {
      background-blend-mode: multiply;
      background-color: map-get($primary, primary10);
      border: $border-width-1 solid map-get($primary, primary100);
    }

    p {
      font-family: $font-family-text-font;
      font-size: $font-size-xsmall;
      font-weight: $font-weight_400;
      line-height: $line-height-120;
      color: map-get($primary, primary100);
      margin-top: 0;
    }

    p:first-child {
      font-size: $font-size-base;
      font-weight: $font-weight_700;
    }
}

.optionBox:focus {
  box-shadow: $box-shadow-focus;
}

.selected {
  background-blend-mode: multiply;
  background-color: map-get($primary, primary10);
  border: $border-width-1 solid map-get($primary, primary100);
}
