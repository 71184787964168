@use "sass:map";
@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints";

$user-info-container-exterior: 15rem;

.dropDownMenu {
  box-shadow: none;
}

.personContainer {
  position: relative;
  display: flex;
  min-height: 4.6875rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  border-radius: 0.3125rem;
  margin: 0.1875rem 0 0;
  cursor: pointer;

  &.active {
    background-color: map.get($secondary-colors, hub_light_grey);
  }

  &:hover {
    background-color: map.get($secondary-colors, hub_light_grey);
  }

  &:focus {
    background-color: map.get($secondary-colors, hub_light_grey);
    box-shadow: 0 0 0 1px map.get($primary-colors, primary), 0 0 0 2px $color-white;
    outline: none;
  }

  .dropDownMenu {
    button {
      box-shadow: none;
    }
  }

  .middleContainer {
    display: flex;
    width: 25%;
    align-items: center;
    text-align: left;

    .textContainer span:first-child,
    .textContainer span:last-child {
      display: block;
      color: map.get($typography-colors, sub-text);
      font-size: 0.8125rem;

      @media #{$phoneMedia} {
        display: none;
      }
    }

    .textContainer span:nth-child(2) {
      color: map.get($legacy-color-names, tooltip);
      font-size: 1rem;

      &.active {
        color: map.get($hubColors, hub_active_licence);
      }

      &.archived {
        color: map.get($validation-colors, error);
      }

      @media #{$phoneMedia} {
        display: none;
      }
    }

    .svgContainer svg {
      width: 1.6rem;
      margin-right: 0.375rem;
    }

    .svgContainer .statusTooltip span {
      z-index: 1000;
    }

    @media #{$phoneMedia} {
      width: initial;
    }
  }

  .leftContainer {
    display: flex;
    width: 60%;
    align-items: center;

    @media #{$phoneMedia} {
      width: initial;
    }

    .checkboxContainer {
      .placeholder {
        z-index: -1;
        overflow: hidden;
        width: 1.5625rem;
        opacity: 0;
        pointer-events: none;
      }
    }

    .userDetailsContainer {
      display: flex;
      align-items: center;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      @media #{$phoneMedia} {
        margin-left: 0.75rem;
      }
    }

    .userInfoDot {
      display: flex;
      width: 1.6rem;
      height: 1.6rem;
      align-items: center;
      justify-content: center;
      padding: 1.6rem;
      border-radius: 50%;
      margin: 0 0.75rem;
      font-size: 1rem;
      font-weight: 600;
      text-transform: uppercase;

      @media #{$phoneMedia} {
        width: 1.3rem;
        height: 1.3rem;
        padding: 1.3rem;
        margin-left: 0;
      }
    }

    .student {
      background-color: map.get($secondary-colors, learner);
      color: map.get($typography-colors, text);
    }

    .teacher {
      background-color: map.get($secondary-colors, classroom);
      color: $color-white;
    }

    .teacherAdmin {
      background-color: map.get($secondary-colors, teacher_admin);
      color: map.get($typography-colors, text);
    }

    .orgAdmin {
      background-color: map.get($secondary-colors, organization);
      color: map.get($typography-colors, text);
    }

    .userInfoContainer {
      @media #{$phoneMedia} {
        width: calc(#{$viewportWidth} - #{$user-info-container-exterior});
      }

      div:first-child {
        span {
          color: map.get($primary-colors, primary);
          font-size: 1rem;

          @media #{$phoneMedia} {
            display: inline-block;
            overflow-x: hidden;
            white-space: nowrap;
          }
        }
      }

      div:last-child {
        span {
          color: map.get($typography-colors, sub-text);
          font-size: 0.8125rem;

          @media #{$phoneMedia} {
            display: inline-block;
            overflow-x: hidden;
          }
        }
      }
    }

    &:hover .userInfoContainer div:first-child {
      span {
        color: map.get($primary-dark-colors, primary-dark);
      }
    }
  }

  &.personRemoved {
    cursor: default;

    .leftContainer {
      .userInfoContainer div:first-child {
        span {
          color: map.get($typography-colors, text);
        }
      }

      .student,
      .teacher,
      .teacherAdmin,
      .orgAdmin {
        opacity: 0.5;
      }
    }
  }

  .rightContainer {
    width: 15%;
    text-align: right;

    @media #{$phoneMedia} {
      display: none;
    }
  }

  .dropDownPlaceholder {
    width: 2.5rem;
  }
}

.checkboxPlaceholder {
  width: 1.5625rem;
}
