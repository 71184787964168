@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";
$minColWidth: 220px;
$resourceMinWidth: 140px;
$maxColWidth: 1fr;

.userCoursesMain {
  padding: 1.5rem;

  .courses {
    display: grid;
    margin-bottom: 40px;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(auto-fill, minmax($minColWidth, $maxColWidth));
  }
}

.hubEmptyState {
  display: flex;
}

.hubEmptyStateMessage {
  order: 2;
  width: 100%;
}

@media #{$phoneMedia} {
  .userCoursesMain {
    padding: 1.5rem 1rem;

    .courses {
      grid-template-columns: none;
    }
  }

  .hubEmptyState {
    display: grid;
  }
}
