@import "../../../../styles/constants/breakpoints";
@import "../../../../styles/constants/colors";
@import "../../../../styles/constants/variables";

.homePageSectionOne {
  background-color: map-get($hubColors, hub_neutral_blue);
  color: map-get($background-and-border-colors, background-dark-blue);
  font-family: $open-sans;

  @media #{$smMedia} {
    padding-bottom: 2.5rem;
  }
}

@media #{$mdMedia} {
  .homePageSectionOne .primaryImgContainer {
    height: 36vw;
  }
}
