@use "sass:map";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";
@import "~@oup/shared-front-end/dist/styles/breakpoints";

.chooseOrgTypeStep {
  display: flex;
  flex-direction: column;
  padding: $gap-6 $gap-5 $gap-5 $gap-6;
  gap: $gap-5;
  max-width: 40.5rem;
}

.heading {
  font-family: $font-family-display-font;
  padding: 0;
}

.chooseOrgTypeDescription {
  display: flex;
  padding: 1.4rem;
  background-color: map.get($neutral, neutral-blue20);
  gap: 1.4rem;

  @media (max-width: $screen-width-480) {
    flex-direction: column;
  }

  .images {
    display: flex;
    width: 100%;
    max-width: 12.5rem;
    flex-direction: column;
    gap: 4rem;

    img {
      max-width: 100%;
    }
  }

  .link {
    margin-top: 1rem;

    a {
      display: block;
    }
  }
}

.radioButtonGroupContainer {
  display: flex;
  flex-flow: column;
  gap: $gap-3;

  @media (min-width: $screen-width-480) {
    flex-flow: row wrap;

    > label {
      flex-basis: 0;
      flex-grow: 1;
    }
  }
}


.RadiobuttonImage {
  width: $icon-size-medium;
  height: $icon-size-medium;
}
