@import "../../../styles/constants/breakpoints";

.tileSkeleton {
  display: grid;
  max-height: 9.0625rem;
  flex-wrap: nowrap;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(13.76rem, 18.75rem));
  overflow-y: hidden;

  svg {
    width: 100%;
    height: 9.0625rem;
  }
}

.lastOpenedTileSkeleton {
  max-height: unset;
  grid-template-columns: unset;

  svg {
    overflow: hidden;
    width: 100%;
    height: 9.0625rem;
    border-radius: 0.5rem;
  }
}

.wordOfTheDayTileSkeleton {
  max-height: unset;

  svg {
    height: 19rem;
  }
}

@media #{$phoneMedia} {
  .tileSkeleton {
    overflow: unset;
    max-height: unset;
    grid-auto-columns: unset;

    svg {
      max-width: unset;
      height: 6.375rem;
    }
  }
}
