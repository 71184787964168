@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";

.hubPanelMenuOverlay {
  z-index: -1;
  left: unset;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-20%);
  transition: transform 0.1s cubic-bezier(0.52, 0.53, 0.61, 0.8);

  &.opened {
    z-index: 9;
    opacity: 1;
    pointer-events: unset;
    transform: translateY(0);
    transition: transform 0.1s cubic-bezier(0.52, 0.53, 0.61, 0.8);
  }
}

.menuPanelContainer {
  min-width: 12.5rem;
  background-color: $color-white;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 $contrast-light-accent-50;
  cursor: pointer;

  .menuItem {
    button {
      display: flex;
      width: 100%;
      height: 3.125rem;
      color: map-get($typography-colors, text);
      font-size: 1rem;
      line-height: 3.125rem;
      text-align: unset;

      &:focus {
        @include no-padding-button-on-focus();

        background-color: map-get($secondary-colors, hub_light_grey);
        outline: unset;
      }
    }

    span {
      width: 100%;
      border-top: 1px solid map-get($background-and-border-colors, border);
      margin-right: 1rem;
    }

    &:hover {
      background-color: map-get($secondary-colors, hub_light_grey);
    }

    &:first-of-type {
      span {
        border-top: none;
      }

      &:hover {
        border-radius: 5px 5px 0 0;
      }
    }

    &:last-of-type:hover {
      border-radius: 0 0 5px 5px;
    }

    .svgContainer {
      display: flex;
      min-width: 3.125rem;
      height: 100%;
      align-items: center;
      justify-content: center;
      line-height: 3.125rem;

      svg {
        width: 100%;
        max-width: 1.5625rem;
        max-height: 1.25rem;
      }
    }
  }
}

.addToButton {
  position: relative;
  min-height: 2.5rem;
  padding: 0.5rem 1.25rem;
  margin-bottom: 0.5rem;
  background-color: map-get($hubColors, hub_link);
  border-radius: 5rem;
  color: $color-white;

  &.addToButtonActive,
  &:hover {
    background-color: map-get($hubColors, blue-color-tertiary);
    color: $color-white;
    outline: none;
  }

  &:focus {
    @include standart-focus-button();

    outline: none;
  }

  span {
    margin-right: 1.5rem;
  }

  .thumbnailContainer {
    position: absolute;
    top: 60%;
    right: 0.8rem;
    transform: translateY(-50%);

    svg {
      width: 15px;
      height: 13px;
      box-sizing: content-box;
      transition: 0.1s ease-out transform;
    }

    &.thumbnailActive svg {
      transform: rotate(270deg);
    }
  }
}
