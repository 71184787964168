@import "../../../styles/constants/colors.scss";

// Step animation
.step {
  // Fade-in
  animation-duration: 300ms;
  animation-iteration-count: initial;
  animation-name: fadeIn;
  animation-timing-function: ease;

  // Fade-out
  transition-duration: 300ms;
  transition-property: opacity;
  transition-timing-function: ease-in;

  &.fadeOut {
    opacity: 0;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(30px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

// Cancel and Skip buttons - fade-out animation
.cancelOrSkip {
  opacity: 1;
  transition-duration: 300ms;
  transition-property: opacity;
  transition-timing-function: ease-in;

  &.fadeOut {
    opacity: 0;
  }
}

// Next button - spinner animation
.nextButton {
  position: relative;
  min-width: 65px;
  margin-left: auto;
  border-width: 1px;

  &.loading::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    border: 2px solid $color-black;
    border-top-color: map-get($background-and-border-colors, background);
    border-right-color: map-get($background-and-border-colors, background);
    border-left-color: map-get($background-and-border-colors, background);
    margin-top: calc((-50% + 16px) / 2);
    margin-left: calc((-50% + 16px) / 2);
    animation: spin 1s infinite linear;
    border-radius: 100%;
    content: "";
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}
