@use "sass:map";
@import "~@oup/shared-front-end/dist/styles/breakpoints";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";

.heading {
  padding: $gap-0;
  font-family: $font-family-display-font;
}

.radioButtonsContainer {
  flex-direction: row;

  @media (max-width: $screen_width_600) {
    flex-direction: column;
  }
}

.chooseRoleRadioButton svg {
  width: $icon_size_medium;
  height: $icon_size_larger;
  color: map.get($primary, primary100);
  fill: map.get($primary, primary100);
}

.chooseAgeRadioButton p {
  font-weight: $font-weight-700;
}

.wizardStep {
  padding-right: $gap-1;
  padding-left: $gap-1;
}

.helpButtonLarge {
  display: flex;

  @media (max-width: $screen-width-840) {
    display: none;
  }
}

.helpButtonSmall {
  display: flex;

  @media (min-width: $screen-width-840) {
    display: none;
  }
}

.helpIconLoggedOut {
  fill: map.get($primary, primary100);
}

.outerContainer {
  padding: $gap-6;
}

.chooseRoleInnerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: $gap-5;
  gap: $gap-6;
}

.chooseAgeOuterContainer {
  width: 100%;
  max-width: 70rem;
  padding: $gap-6;
}

.chooseAgeInnerContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-top: $gap-5;
  gap: $gap-6;

  .textContent {
    display: flex;
    width: 60%;
    flex-direction: column;
    gap: $gap-6;
  }

  .imageContainer {
    display: flex;
    width: 40%;
    justify-content: center;

    img {
      width: 100%;
      max-width: 22rem;
    }
  }

  @media (max-width: $screen_width_600) {
    flex-direction: column;

    .textContent, .imageContainer {
      width: 100%;
    }
  }
}
