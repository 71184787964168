@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints.scss";

.container {
  &:not(.expanded) {
    .paginator button {
      color: var(--product-contrast-color);

      &,
      &:hover,
      &:active {
        text-decoration: underline;
      }
    }
  }

  &.multiImageType {
    position: relative;
  }

  &.expanded {
    margin-bottom: 10px;

    .paginator {
      min-height: 40px;
      margin-top: 20px;
    }

    .wrapper .sections {
      height: calc(140px * var(--number-of-items) + 10px * (var(--number-of-items) - 1));
      margin-bottom: 0;
      grid-template-columns: 100%;
      transition: all 300ms;

      @media only screen and (max-width: 1023px) {
        grid-gap: 10px;

        section {
          width: 100%;
          max-width: none;
          height: auto;
          grid-gap: 10px;
          grid-template-columns: 100%;

          &:last-child > *:last-child {
            margin-right: 0;
          }
        }
      }

      @media only screen and (min-width: 1024px) {
        height: calc(140px * var(--number-of-sections) + 20px * (var(--number-of-sections) - 1));
      }
    }

    @media only screen and (max-width: 1023px) {
      &.mainPageVisible {
        padding-bottom: 0;
      }

      .paginator button {
        position: fixed;
        z-index: 3;
        right: auto;
        bottom: 100px;
        left: auto;
        border: 1px solid $color-white;
      }

      .inContainer button {
        position: static;
      }
    }
  }

  .wrapper {
    margin-right: -30px;
    margin-left: -30px;

    &.multiImageWrapper {
      margin: 0;
    }

    @media only screen and (min-width: 1024px) {
      margin: 0;
    }

    .sections {
      display: grid;
      overflow: hidden;
      padding: 0 30px;
      margin: 10px 0;
      grid-gap: 20px;
      grid-template-columns: repeat(10, 100%);
      scroll-behavior: smooth;

      @media only screen and (max-width: 1023px) {
        overflow: -moz-scrollbars-none;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        overflow-x: auto;
        scroll-padding: 0 30px;
        scroll-snap-type: x proximity;
        scrollbar-width: none; /* FF */

        &::-webkit-scrollbar {
          width: 0 !important;
        }
      }

      @media only screen and (min-width: 401px) {
        grid-template-columns: repeat(10, 400px);
      }

      @media only screen and (min-width: 701px) {
        grid-template-columns: repeat(10, 100%);
      }

      @media only screen and (min-width: 1024px) {
        padding: 0;
        margin: 10px 0;
      }

      section {
        display: grid;
        width: calc((100% / var(--per-section)) * var(--items-in-section));
        max-width: 400px;
        height: 137px;
        grid-gap: 20px;
        grid-template-columns: repeat(var(--items-in-section), 1fr);
        border: 0;

        @media only screen and (max-width: 1023px) {
          > * {
            scroll-snap-align: start;
          }

          &:last-child > *:last-child {
            margin-right: 30px;
            scroll-snap-align: end;
          }
        }

        @media only screen and (min-width: 701px) {
          max-width: none;
        }

        @media only screen and (min-width: 1024px) {
          width: auto;
          grid-template-columns: repeat(var(--per-section), 1fr);
        }
      }
    }
  }

  /* Pagination footer */

  .footer {
    display: grid;
    grid-template-columns: 40px auto 40px;

    &.multiImageFooter {
      grid-template-columns: unset;
    }
  }

  .carouselButton {
    align-self: center;

    button {
      width: 40px;
      height: 40px;
      padding: 0;
      border-radius: 100%;

      &:focus,
      &:hover {
        background-color:map-get($background-and-border-colors, border-grey);
        box-shadow: inset 0 0 0 2px var(--product-contrast-color);
      }

      &,
      &:focus,
      &:hover {
        svg {
          fill: var(--product-contrast-color);
        }
      }

      svg {
        width: 20px;
      }
    }

    &.prev {
      svg {
        transform: rotate(180deg) translateX(2px);
      }
    }

    &.next {
      svg {
        transform: rotate(0deg) translateX(2px);
      }
    }

    &.galleryButton {
      position: absolute;
      top: 30%;

      &.prev {
        left: 10px;

        @media #{$mdMedia} {
          left: -40px;
        }
      }

      &.next {
        right: 10px;

        @media #{$mdMedia} {
          right: -40px;
        }
      }

      button {
        min-width: 44px;
        border-radius: 4px;

        &:focus {
          background-color: map-get($background-and-border-colors, background);
        }

        &:hover {
          background-color: map-get($background-and-border-colors, selected);

          svg {
            fill: map-get($background-and-border-colors, background-dark-blue);
            stroke: map-get($background-and-border-colors, background-dark-blue);
          }
        }

        svg {
          height: 14px;
          fill: map-get($background-and-border-colors, background-light-blue);
          stroke: map-get($background-and-border-colors, background-light-blue);
          stroke-width: 5px;
        }
      }
    }
  }

  .paginator {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    .dot {
      display: none;
      width: 9px;
      height: 9px;
      border: 1.5px solid var(--product-contrast-color);
      margin-right: 10px;
      background-color: transparent;
      border-radius: 10px;
      cursor: pointer;

      @media only screen and (min-width: 1024px) {
        display: block;
      }

      &.active {
        background-color: var(--product-contrast-color);
      }

      &:focus,
      &:hover {
        background-color:map-get($background-and-border-colors, border-grey)
      }

      &.gallery {
        display: block;
        width: 16px;
        height: 16px;
        border: 1.5px solid map-get($background-and-border-colors, border);
        margin-top: 0;
        margin-right: 12px;
        background-color: $color-panel-background;

        @media #{$mdMedia} {
          margin-top: 1.5rem;
        }

        &.active,
        &:focus,
        &:hover {
          background-color: map-get($background-and-border-colors, background-light-blue);
        }
      }
    }

    .pipe {
      display: none;
      margin-right: 15px;
      margin-left: 10px;
      color: var(--product-contrast-color);
      opacity: 0.3;

      @media only screen and (min-width: 1024px) {
        display: block;
      }
    }
  }
}

:global(.contrast-light) {
  .carouselButton {
    button {
      &:focus,
      &:hover {
        background-color: $contrast-light-accent;
      }
    }
  }
}
