@import "@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "@oup/shared-front-end/dist/styles/themes/base/typography";

.stepContainer {
  display: flex;
  flex-direction: column;
  padding: $gap-6 $gap-5 0 $gap-5;
  gap: $gap-6;
}

.heading {
  font-family: $font-family-display-font;
  padding: 0;
}

.showTestResultsColumn {
  padding: $gap-4;

  .customiseToggle {
    display: flex;
    align-items: center;
    font-weight: 600;
    gap: $gap-4;
  }

  .noteText {
    margin-top: $gap-4;
  }
}
