@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";

.addClassroom {
  position: relative;
  display: grid;
  min-width: 13.75rem;
  max-width: 18.75rem;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  order: 1;
  border: 1px solid map-get($background-and-border-colors, border);
  margin-right: 1px;
  margin-bottom: 2px;
  background-color: $color-panel-background;
  border-radius: 5px;
  box-shadow: 0 0 0 1px $contrast-light-hover;
  cursor: pointer;
  grid-gap: 0.5rem;

  .addContainer {
    padding: calc(100% - 144px) 0;
    background-color: $color-panel-background;
    color: map-get($background-and-border-colors, background-light-blue);
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
  }

  .addIcon {
    display: flex;
    width: 3rem;
    height: 3rem;
    padding: 0.625rem;
    margin: 0.25rem auto 0 auto;
    background-color: map-get($background-and-border-colors, background-light-blue);
    border-radius: 50%;

    svg {
      fill: $color-white;
    }
  }

  &:hover {
    border: solid 1px map-get($background-and-border-colors, background-light-blue);
    box-shadow: 0 0 0 1px map-get($background-and-border-colors, background-light-blue);

    .addText {
      color: map-get($hubColors, hub_link_hover);
    }

    .addIcon {
      filter: brightness(70%);
    }
  }

  &:focus {
    @include tile-on-focus();

    .addText {
      color: map-get($hubColors, hub_link_hover);
    }

    .addIcon {
      filter: brightness(70%);
    }
  }
}

@media #{$phoneMedia} {
  .addClassroom {
    max-width: none;

    .addContainer {
      display: flex;
    }

    .addText {
      order: 2;
      margin: auto 0 auto 10px;
    }

    .addIcon {
      width: 3.25rem;
      height: 3.25rem;
      order: 1;
      border-radius: 100%;
      float: left;
    }
  }
}
