@use "sass:map";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";
@import "~@oup/shared-front-end/dist/styles/breakpoints";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";

.introductionContainer {
  display: flex;
  width: 100%;
  height: 100%;

  .organizationSetupSteps {
    padding-bottom: 3rem;
    padding-left: 1.5rem;
    font-size: $font-size-base;
    font-weight: $font-weight-700;
    letter-spacing: -0.05rem;
    line-height: 1.5rem;

    li {
      display: list-item;

      p:nth-child(2) {
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: $font-weight-400;
      }
    }
  }

  .yellowBackground {
    background-color: map.get($modern, kipper-yellow);
  }
}
