@import "../../../styles/constants/colors";
@import "../../../styles/constants/breakpoints";

$userInfoContainerExterior: 15rem;

.dropDownMenu {
  box-shadow: none;
}

.personContainer {
  position: relative;
  display: flex;
  min-height: 4.6875rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  margin: 0.1875rem 0 0 0;
  border-radius: 0.3125rem;
  cursor: pointer;

  &.active {
    background-color: map-get($secondary-colors, hub_light_grey);
  }

  &:hover {
    background-color: map-get($secondary-colors, hub_light_grey);
  }

  .dropDownMenu {
    button {
      box-shadow: none;
    }
  }

  .leftContainer svg {
    border: 1px solid $color-light-grey;
    fill: $color-white;
  }

  .middleContainer {
    display: flex;
    width: 25%;
    align-items: center;
    text-align: left;

    .textContainer span:first-child,
    .textContainer span:last-child {
      display: block;
      color: map-get($typography-colors, sub-text);
      font-size: 0.8125rem;

      @media #{$phoneMedia} {
        display: none;
      }
    }

    .textContainer span:nth-child(2) {
      color: map-get($legacy-color-names, tooltip);
      font-size: 1rem;

      &.active {
        color: map-get($hubColors, hub_active_licence);
      }

      &.archived {
        color: map-get($validation-colors, error);
      }

      @media #{$phoneMedia} {
        display: none;
      }
    }

    .svgContainer svg {
      width: 1.6rem;
      margin-right: 0.75rem;
    }

    .svgContainer .statusTooltip span {
      width: 6rem;
    }

    @media #{$phoneMedia} {
      width: initial;

      .svgContainer svg {
        margin-right: 0.375rem;
      }
    }
  }

  .leftContainer {
    display: flex;
    width: 60%;
    align-items: center;

    @media #{$phoneMedia} {
      width: initial;
    }

    .checkboxContainer {
      .placeholder {
        z-index: -1;
        overflow: hidden;
        width: 1.5625rem;
        opacity: 0;
        pointer-events: none;
      }
    }

    .userInfoDot {
      display: flex;
      width: 1.6rem;
      height: 1.6rem;
      align-items: center;
      justify-content: center;
      padding: 1.6rem;
      margin: 0 0.75rem;
      border-radius: 50%;
      font-size: 1rem;
      font-weight: 600;
      text-transform: uppercase;

      @media #{$phoneMedia} {
        width: 1.3rem;
        height: 1.3rem;
        padding: 1.3rem;
        margin-left: 0;
      }
    }

    .student {
      background-color: map-get($secondary-colors, learner);
      color: map-get($typography-colors, text);
    }

    .teacher {
      background-color: map-get($secondary-colors, classroom);
      color: $color-white;
    }

    .classAdmin {
      background-color: map-get($secondary-colors, organization);
      color: map-get($typography-colors, text);
    }

    .orgAdmin {
      background-color: map-get($secondary-colors, organization);
      color: $color-white;
    }

    .userDetailsContainer {
      display: flex;
      margin: 0 1rem 0 0;

      &::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
      }

      @media #{$phoneMedia} {
        margin-left: 0.75rem;
        overflow-x: scroll;
      }
    }

    .userInfoContainer {
      @media #{$phoneMedia} {
        width: calc(#{$viewportWidth} - #{$userInfoContainerExterior});

        span {
          white-space: nowrap;
        }
      }
    }

    .userInfoContainer div:first-child {
      span {
        color: map-get($primary-colors, primary);
        font-size: 1rem;
      }
    }

    .userInfoContainer div:last-child {
      span {
        color: map-get($typography-colors, sub-text);
        font-size: 0.8125rem;
      }
    }

    &:hover .userInfoContainer div:first-child {
      span {
        color: map-get($primary-dark-colors, primary-dark);
      }
    }
  }

  &.personRemoved {
    cursor: default;

    .leftContainer {
      .userInfoContainer div:first-child {
        span {
          color: map-get($typography-colors, text);
        }
      }

      .student,
      .teacher,
      .teacherAdmin,
      .orgAdmin {
        opacity: 0.5;
      }
    }
  }

  .rightContainer {
    width: 15%;
    text-align: right;

    @media #{$phoneMedia} {
      display: none;
    }
  }
}
