@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints";
@import "../../styles/constants/variables";

.languageSelector {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  .selectorButton {
    display: flex;
    height: 42px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid transparent;
    background: $color-white;
    border-radius: 5px;
    color: map-get($hubColors, hub_link);
    font-family: $open-sans;

    &:hover:not(:focus),
    &.selected {
      border-color: color(border);
      color: map-get($hubColors, blue-color-dark);

      svg {
        fill: map-get($hubColors, blue-color-dark);
      }
    }

    &.selected {
      background: map-get($secondary-colors, hub_light_grey);
    }

    &:focus.selected {
      border-color: color(border);
      background: map-get($secondary-colors, hub_light_grey);
      box-shadow: 0 0 0 1px $color-white, 0 0 0 3px map-get($primary-colors, primary), 0 0 0 4px $color-white;
      color: map-get($hubColors, blue-color-dark);

      svg {
        fill: map-get($hubColors, blue-color-dark);
      }
    }

    &:focus {
      box-shadow: 0 0 0 1px $color-white, 0 0 0 3px map-get($primary-colors, primary), 0 0 0 4px $color-white;
      color: map-get($hubColors, hub_link);

      svg {
        fill: map-get($hubColors, hub_link);
      }
    }

    span {
      min-width: 33px;
      margin: 0 10px;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -0.017em;
      text-align: center;

      &:nth-of-type(1) {
        display: block;
      }

      &:nth-of-type(2) {
        display: none;
      }
    }

    svg {
      width: 20px;
      height: 20px;
      fill: map-get($hubColors, hub_link);
      transition: width 0.1s cubic-bezier(0.52, 0.53, 0.61, 0.8);

      &:nth-of-type(1) {
        left: 15px;
      }

      &:nth-of-type(2) {
        right: 15px;
      }
    }
  }

  .menu {
    z-index: -1;
    top: 100%;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-20%);
    transition: transform 0.1s cubic-bezier(0.52, 0.53, 0.61, 0.8);

    &.opened {
      z-index: 9;
      opacity: 1;
      pointer-events: unset;
      transform: translateY(0);
      transition: transform 0.1s cubic-bezier(0.52, 0.53, 0.61, 0.8);
    }

    .optionContainer {
      min-width: 10rem;
      background: $color-white;
      border-radius: 5px;
      box-shadow: 0 0 5px 0 $contrast-light-accent-50;
      color: black;
      cursor: pointer;

      .option {
        button {
          display: flex;
          width: 100%;
          height: 2rem;
          color: map-get($typography-colors, text);
          font-size: 1rem;
          line-height: 2rem;
        }

        span {
          width: 100%;
          text-align: center;
        }

        &:hover {
          background: map-get($secondary-colors, hub_light_grey);
        }
      }
    }
  }

  &.darkTheme {
    .selectorButton {
      background-color: map-get($background-and-border-colors, background-dark-blue);
      color: $contrast-dark-accent-08;

      &:hover {
        border-color: map-get($background-and-border-colors, border-grey);
      }

      &:hover,
      &.selected {
        color: color(background);

        svg {
          fill: color(background);
        }
      }

      &.selected {
        border-color: color(border);
        background-color: map-get($background-and-border-colors, border-grey);
      }

      &.selected:focus {
        color: color(background);

        svg {
          fill: color(background);
        }
      }

      &:focus {
        box-shadow: 0 0 0 1px $color-white, 0 0 0 3px map-get($primary-colors, primary), 0 0 0 4px $color-white;
      }

      svg {
        fill: $contrast-dark-accent-08;
      }
    }
  }

  @media #{$xsMedia} {
    .selectorButton {
      span {
        &:nth-of-type(1) {
          display: none;
        }

        &:nth-of-type(2) {
          display: block;
        }
      }
    }
  }

  @media #{$phoneMedia} {
    .selectorButton {
      width: 80px;
      height: 52px;

      span {
        &:nth-of-type(2) {
          display: none;
        }
      }

      svg {
        &:nth-of-type(1) {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
