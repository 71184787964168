@import "~@oup/shared-front-end/dist/styles/breakpoints";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";

.heading {
  padding: 0;
  font-family: $font-family-display-font;
}

.stepContainer {
  display: flex;
  flex-direction: row;
  padding: $gap-6 $gap-5;
  gap: $gap-5;

  @media (max-width: $screen-width-600) {
    flex-direction: column;
  }
}

.leftContainer {
  display: flex;
  width: 57%;
  flex-direction: column;
  padding: $gap-5 $gap-0;
  gap: $gap-6;

  @media (max-width: $screen-width-600) {
    width: 100%;
  }
}

.rightContainer {
  width: 42%;
  padding: $gap-5 $gap-0;

  @media (max-width: $screen-width-600) {
    width: 100%;
  }

  .image {
    width: 100%;
    height: 18rem;
  }
}
