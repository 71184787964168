@import "../../../../../../styles/constants/colors";
@import "../../../../../../styles/constants/breakpoints";

.addDeploymentTool {
  padding: 1em;

  .howToBanner {
    margin-bottom: 1em;
  }

  .howToBanner a {
    display: block;
    margin-top: 1em;
  }

  .oxfordLTIConfig {
    padding: 1em;
    border: solid 1px $color-light-grey;
    background-color: $color-panel-background;
    border-radius: 5px;
  }

  .toggleButton {
    display: block;
    padding: 2px 16px;
    border: solid 1px $color-light-grey;
    margin: 0 auto;
    background-color: white;
    border-radius: 20px;
    color: map-get($typography-colors, sub-text);
  }

  .icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
  }

  div[class^='TextInputField'] {
    overflow: hidden;
    background-color: white;
    text-overflow: ellipsis;
  }

  .orgConfigFields {
    padding: 0 1em;
    margin-top: 1em;
    background-color: map-get($secondary-colors, hub_light_grey);
    border-radius: 5px;
  }

  .editOrgConfig {
    padding: 4px 8px;
    border-width: 1px 1px 2px 1px;
    border-style: solid;
    border-color: map-get($hubColors, dark-gray);
    margin: 1em 0;
    background-color: white;
    border-radius: 5px;

    &:hover {
      border-color: map-get($legacy-color-names, tooltip);
    }
  }

  .fieldInfo {
    display: inline-block;
    margin-top: 8px;
  }
}

.toolInfoList {
  margin: 0 1em;
}

.toolInfoList li:not(:last-child) {
  border-bottom: solid 1px $color-light-grey;
}

.toolInfoContainer {
  display: flex;
  align-items: center;
  margin: 1em;
}

.toolInfoDot {
  display: flex;
  min-width: 1.6rem;
  max-width: 1.6rem;
  height: 1.6rem;
  box-sizing: content-box;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  margin: 0 0.75rem 0 0;
  background-color: map-get($secondary-colors, organization);
  border-radius: 50%;

  svg {
    width: 100%;
    border: none;
    fill: white;
  }

  @media #{$phoneMedia} {
    width: 1.3rem;
    height: 1.3rem;
    padding: 1.3rem;
  }
}
