@use "sass:map";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/breakpoints";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";

.customiseToggleContainer {
  .subtitleTestName {
    display: inline-block;
    padding: 0 0 0 0.4rem;
    font-weight: bold;
  }

  .customClassNameForToggle {
    padding: 0.625rem;
    font-weight: 400;
    line-height: 1.5;
  }

  .toggleButtonAndText {
    display: flex;
    align-items: center;

    .allowStudents {
      display: inline-flex;
      margin: 0 0 0 1.25rem;
      font-weight: 600;
    }
  }

  .joiningCode {
    display: block;
    padding: 0.9rem 0 0.5rem;
    font-weight: 800;
  }

  .manageJoiningCode {
    border-top: 0.0625rem solid map.get($primary, primary20);

    .manageJoiningCodeContent {
      padding: 1rem
    }
  }

  .placesRemaining {
    display: inline-block;
    padding-top: 0.3125rem;
  }

  .placesRemainingText {
    display: block;
    padding: 0.3125rem 0;
    font-weight: 800;
  }

  .disabled {
    background-color: map.get($primary, primary10);
  }

}

.ifCodeIsChanged,
.ifCodeHasError {
  display: inline-block;
  max-width: 99%;
  padding-top: 0.5rem;
  font-weight: 600;
}

.ifCodeHasError {
  color: map.get($validation, validation-red100);
}

.errorMessageContainer {
  display: flex;
  flex-direction: column;

  .errorMessage {
    color: map.get($validation, validation-red100);
    font-weight: 600;
  }
}

.loadingText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .pleaseWait {
    border: none;
    font-size: 1.5625rem;
    font-weight: 800;
    outline: none;
  }

  .updatingSession {
    color: #ccd3da;
    font-size: 0.9375rem;
  }
}

.copyToStudents {
  display: block;
  max-width: 80%;
  padding: 0.5rem 0 0;

}

.disabledColor {
  div {
    background-color: rgba(map.get($primary, primary20), 0.2);

    p {
      color: rgba(map.get($primary, primary20), 1);
    }
  }

  button {
    background-color: rgba(map.get($primary, primary20), 0.2);
    cursor: auto;
  }

}

.learnMoreAboutCodes {
  padding: 1rem 0;
}

.imageContainerSuccess {
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10%;

  h1 {
    font-weight: 800;
    text-align: center;
  }

  img {
    width: 45%;
  }
}

.updateSuccess {
  position: relative;
  width: 100%;
  height: 100%;
}

.doneButtonContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.textInputClassCodeContainer {
  position: relative;
  display: flex;
  width: 100%;

  .copyCodeButton {
    border-left: none;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;

  }

  .placeHolderClassCode {
    position: absolute;
    z-index: 1;
    top: 25%;
    left: 1%;
    display: block;
    padding-left: 0.7rem;
    font-weight: bold;
  }
}

.inputAndInfoIconContainer {
  width: 100%;
  height: 100%;
}

.infoIcon {
  position: absolute;
  width: $icon-size-regular;
  height: $icon-size-regular;
  inset: 50% calc(#{$icon-size-regular} / 2 + 6.875rem) auto auto;
  pointer-events: none;
  transform: translateY(-50%);
}

.codeEditInput {
  width: 100%;
  min-height: 3rem;
  border: 0.0625rem solid #8090a3;
  border-radius: 0.5rem 0 0 0.5rem;
  outline: none;

  &:focus {
    outline: map.get($primary, primary100);
    outline-offset: 0.125rem;
  }

}

.codeEditInputError {
  border: 0.0625rem solid map.get($validation, validation-red100);
}
