@use "sass:map";
@import "../../../../styles/constants/colors";
@import "../../../../styles/constants/breakpoints";
@import "../../../../styles/mixins/hub/hub-button-focus";

$color-draft: rgb(219 110 0);
$color-active-upcoming: rgb(43 130 80);
$color-completed: rgb(43 130 80);

.modalWrapper {
  position: fixed;
  z-index: 102;
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  padding: 0;
  margin: 0;
  background-color: transparent;
  inset: 0;
  opacity: 1;
  pointer-events: none;
  transition: 0.3s background-color, z-index ease-in-out;

  &.open {
    z-index: 999;
    background-color: $contrast-light-hover;
    pointer-events: unset;
    visibility: initial;
  }

  .modalContainer {
    position: absolute;
    top: -100%;
    left: 50%;
    border-radius: 0.3125rem;
    background-color: $color-white;
    transform: translate(-50%, -50%);
    transition: 0.3s top linear;

    &.animationFadeIn {
      top: 45%;
    }

    @media #{$xsMedia} {
      min-width: 20rem;
    }

    @media #{$mdMedia}{
      min-width: 30rem;
    }

    @media #{$lgMedia}{
      min-width: 40rem;
    }

    .modalHeader {
      padding: 1.5rem;
      text-align: right;

      button {
        margin-left: auto;
      }
    }

    .modalContent {
      min-height: 18.75rem;
      padding: 0 1.25rem;

      .sessionTitlesContainer {
        display: flex;
        margin-bottom: 1.25rem;

        .testTypeCover {
          width: 5rem;
          height: 5.313rem;

          .productCover {
            width: 5rem;
            height: 5rem;
          }

          .iconContainer {
            width: 5rem;
            height: 5rem;
          }
        }

        .productTitles {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 0.625rem;

          .titleContainer {
            display: flex;
            align-items: center;

            .title {
              color: map.get($primary-colors, primary);
              font-size: 1.5rem;
              font-weight: 700;

              @media #{$xsMedia} {
                font-size: 1.3rem;
              }
            }

            .testStatus {
              display: inline-flex;
              height: 1.5rem;
              align-items: center;
              padding: 0 0.5rem;
              border: 1px solid;
              border-radius: 1.25rem;
              margin-right: 0.625rem;
              font-size: 1rem;
              font-weight: 700;

              &.DRAFT,
              &.UPCOMING {
                border-color: $color-draft;
              }

              &.ACTIVE {
                border-color: $color-orb-bright-blue;
              }

              &.ACTIVE::before {
                display: block;
                width: 0.625rem;
                height: 0.625rem;
                border-radius: 50%;
                margin-right: 0.313rem;
                background-color: $color-orb-bright-blue;
                content: '';
              }

              &.COMPLETED {
                border-color: $color-completed;
              }
            }
          }

          .productTitle {
            color: map.get($hubColors, dark-gray);
            font-size: 1.3rem;
            font-weight: 400;

            @media #{$xsMedia} {
              font-size: 1.1rem;
            }
          }
        }
      }

      .sessionDates {
        span {
          display: block;
          padding-top: 0.625rem;
          border-top: 1px solid map.get($cptColors, grey);
          margin: 0.625rem 0;
        }
      }

      .completedSessionBanner {
        display: flex;
        height: 2.5rem;
        align-items: center;
        padding: 0 1.25rem;
        border-radius: 0.5rem;
        background-color: map.get($core, background);

        .assessmentIcon {
          width: 1.5rem;
          height: 1.5rem;
        }

        p {
          margin-left: 0.625rem;
          color: map.get($cptColors, dark_blue);
          font-size: 0.875rem;
          font-weight: 600;
        }
      }
    }

    .modalFooter {
      display: flex;
      justify-content: space-between;
      padding: 1.5rem;
      border-top: 1px solid map.get($cptColors, grey);

    }
  }
}
