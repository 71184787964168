@use "sass:map";
@import "../../../styles/constants/breakpoints";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/mixins/box-shadow";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";

a.sideNavLink {
  justify-content: flex-start;
  padding: 16px 18px;
  cursor: pointer;
  font-family: $font-family-text-font;
  font-weight: $font-weight-700;
  gap: 0;
  letter-spacing: $letter-spacing-0;
  line-height: $line-height-120;
}

.sideNavLink {
  display: flex;
  width: 100%;
  height: 3.75rem;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 18px;
  cursor: pointer;
  font-family: $font-family-text-font;
  font-weight: $font-weight-700;
  letter-spacing: $letter-spacing-0;
  line-height: $line-height-120;

  &:hover {
    background-color: map.get($primary, primary10);
    mix-blend-mode: multiply;
  }

  &:focus {
    box-shadow: $box-shadow-focus-inset;
  }

  &:hover,
  &:hover:focus {
    background-color: map.get($primary, primary10);
    mix-blend-mode: multiply;
  }

  .thumbnailContainer {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;

    svg {
      width: $icon-size-regular;
      height: $icon-size-regular;
      box-sizing: content-box;
      fill: map.get($primary, primary100);
    }
  }

  .itemDetailsContainer {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    margin: 0 12px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    white-space: nowrap;

    &.WithSubtitle {
      flex-wrap: wrap;

      .itemTitle {
        width: 100%;
        font-size: $font-size-base;
      }

      .itemSubTitle {
        overflow: hidden;
        flex-basis: 13rem;
        margin-top: 4px;
        font-size: $font-size-xsmall;
        font-weight: $font-weight-400;
        text-overflow: ellipsis;
      }
    }
  }
}

.sideNav {
  position: fixed;
  z-index: 10;
  display: flex;
  overflow: hidden scroll;
  width: 0;
  height: calc(100vh - 4.7rem);
  min-height: calc(100vh - 4.7rem);
  flex-direction: column;
  justify-content: space-between;
  background-color: map.get($neutral, neutral-blue20);
  color: map.get($primary, primary100);
  -ms-overflow-style: none;
  scrollbar-width: none;
  transition: width 0.3s ease-in-out;

  &::-webkit-scrollbar {
    display: none;
  }

  @media #{$smMedia} {
    width: 4.375rem;

    .enterCodeButton {
      span {
        span {
          display: none;
        }
      }
    }

    &.hovered {
      .enterCodeButton {
        span {
          span {
            display: inline-flex;
          }
        }
      }
      .helpAndSupport {
        div {
          span {
            span {
              display: inline-flex;
            }
          }
        }
      }
    }

  }


  &.hovered {
    width: 18rem;

    .itemDetailsContainer {
      opacity: 1;
    }
  }

  ul {
    width: 100%;
    list-style: none;

    li {
      display: flex;
      width: 100%;
      flex-direction: column;

      &.enterCodeButton {
        padding: $gap-4;
      }

      &.helpAndSupport {
        div {
          padding-top: $gap-4;
          border-top: 1px solid map.get($primary, primary20);
          span {
            span{
              display: none;
              font-size: $font-size-base;
              font-weight: $font-weight-700;
            }
            span:first-of-type {
              svg {
                width: $icon-size-small;
                height: $icon-size-small;
              }
            }
          }
       }
        svg {
          width: $icon-size-regular;
          height: $icon-size-regular;
          box-sizing: content-box;
          fill: map.get($primary, primary100);
          color: map.get($primary, primary100);
        }
      }
    }
  }

  &.open {
    width: 18rem;

    .enterCodeButton {
      span {
        span {
          display: inline-flex;
        }
      }
    }

    .helpAndSupport {
      padding: 0 $gap-4 $gap-4;
      div {
        span {
          span {
            display: inline-flex;
          }
        }
      }
    }

    .itemDetailsContainer {
      opacity: 1;
    }
  }
}

.sideNavItemSelected {
  display: flex;
  border-left: 0.312rem solid map.get($primary, primary100);
  background-color: map.get($modern, kipper-yellow);

  &:focus {
    background-color: map.get($modern, kipper-yellow);
  }

  &:hover,
  &:hover:focus {
    background-color: map.get($modern, kipper-yellow);
    mix-blend-mode: multiply;
  }

  .thumbnailContainer {
    justify-content: center;
    margin-left: 0;
  }
}

.carat {
  transition: all 0.3s ease-in-out;

  div:first-of-type {
    width: $icon-size-small;
    height: $icon-size-small;

    svg {
      padding: 0;
      fill: map.get($primary, primary100);
    }
  }

  &.caratOpen {
    transform: rotate(180deg);
  }
}

.taskBadgeDisplayed {
  display: flex;
  flex-direction: row;
  align-items: center;

  .tasksBadge {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius-default;
    margin-right: 0.5rem;
    background-color: map.get($modern, isadora-pink);
  }
}

.warningIconContainer {
  position: relative;
  display: flex;
  width: $gap-5;
  height: $gap-5;
}

.myDownloads {
  width: calc(100% - $gap-4);
  margin-right: $gap-2;
  margin-bottom: $gap-4;
  margin-left: $gap-2;

  span {
    display: none;
  }

  &.open {
    width: calc(100% - ($gap-4 * 2));
    margin-right: $gap-4;
    margin-left: $gap-4;

    span span {
      display: block;
    }
  }

  &.iOsMargin {
    margin-bottom: 75px;
  }
}

@media #{$phoneMedia} {
  .sideNav {
    width: 0%;

    &.open {
      z-index: 20;
      width: 100%;
    }
  }
}
