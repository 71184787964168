@import '../styles/mixins/hides.scss';
@import '../styles/constants/colors.scss';
@import "../styles/constants/breakpoints";

$mobileContainerExterior: 9.5rem;
$xsMediaContainerExterior: 25rem;

.testContainer {
  display: flex;
  align-items: center;

  .testInfoContainer {
    display: flex;
    align-items: center;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .imgContainer {
      display: flex;
      min-width: 2.425rem;
      height: 3.125rem;
      align-items: center;
      justify-content: center;
      background-color: color(primary-dark);
      border-radius: 5px;

      svg {
        width: 60%;
        height: 70%;
        fill: $color-white;
        opacity: 0.5;
      }

      @media #{$phoneMedia} {
        min-width: 2rem;
        height: 2.5rem;
      }
    }

    .mainInfoContainer {
      margin-left: 0.75rem;

      @media #{$xsMedia} {
        max-width: calc(#{$viewportWidth} - #{$xsMediaContainerExterior});
      }

      @media #{$phoneMedia} {
        max-width: calc(#{$viewportWidth} - #{$mobileContainerExterior});
      }
    }

    .topInfo {
      display: flex;
      flex-direction: row;
      line-height: 1.25rem;

      .statusContainer {
        display: flex;
        height: 1.25rem;
        padding: 0 0.625rem;
        border: 1px solid;
        margin: auto;
        border-radius: 11px;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
      }

      .panelHeading {
        max-width: 100%;

        h2 {
          margin: 1rem 0 0;
          line-height: 1;
        }

        p {
          padding-top: 4px;
          color: $color-sub-text;
        }
      }

      .textTitle {
        color: map-get($hubColors, hub_link);
        font-size: 13px;

        @media #{$xsMedia} {
          white-space: nowrap;
        }
      }
    }

    .dateContainer {
      margin-top: 0.5rem;

      p {
        margin: 0;
        font-size: 13px;

        span:first-of-type {
          font-weight: 600;
        }
      }

      @media #{$xsMedia} {
        p span {
          white-space: nowrap;
        }
      }
    }
  }

  .dropdownMenu {
    .classTestDropdown button:not(:focus) {
      box-shadow: none;
    }
  }

  &.active {
    .dropdownMenu {
      margin-left: auto;
    }

    .testInfoContainer .topInfo .statusContainer {
      border-color: map-get($hubColors, blue-color-primary);

      .pulse {
        display: block;
        width: 12px;
        height: 12px;
        margin-top: 3px;
        margin-right: 4px;
        animation-delay: 0;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-name: stretch;
        animation-play-state: running;
        animation-timing-function: ease-out;
        background-color: map-get($hubColors, blue-color-primary);
        border-radius: 100%;
      }
    }
  }

  &.upcoming {
    .dropdownMenu {
      margin-left: auto;
    }

    .testInfoContainer .topInfo .statusContainer {
      // stylelint-disable value-keyword-case
      border-color: map-get($legacy-color-names, OK);

      .pulse {
        display: none;
      }
    }
  }

  &.completed {
    .dropdownMenu {
      margin-left: auto;
    }

    .testInfoContainer {
      width: 70%;

      .mainInfoContainer {
        @media #{$xsMedia} {
          max-width: calc(#{$viewportWidth} - 30.5rem);
        }

        @media #{$phoneMedia} {
          max-width: calc(#{$viewportWidth} - 12rem);
        }
      }

      .topInfo .statusContainer {
        border-color: map-get($legacy-color-names, good);

        .pulse {
          display: none;
        }
      }
    }

    .scoreContainer {
      width: 15%;
      font-size: 1rem;
      text-align: center;

      span:first-child {
        font-size: 2rem;
      }
    }
  }
}

.totalResults {
  margin-top: 12px;
  text-align: center;
}

.paginationButtons {
  margin: 2rem 1rem;
}

.limitToggle {
  padding: 1rem;
  border-bottom: 1px solid $color-border;
}

.selectedFooter {
  padding: 1rem 0;
  border-bottom: 1px solid map-get($background-and-border-colors, border);
  margin: 0 1rem;

  span + span {
    padding-left: 8px;
    vertical-align: middle;
  }
}

// Contains 2-3 elements: 1 or 2 fields and a totalResults element:
.fields {
  margin-top: 15px;

  // The label of the textbox should be hidden accessibly:
  label[for="selectionSearchTerm-input"] {
    @include a11y-hide();
  }

  // Revealer icon should be blue:
  svg[class] {
    fill: map-get($styleGuideColors, primary);
    transition: transform 0.25s;
  }
}

.hasShowMore {
  zoom: 1;
}

// The show/hide textbox is inside this element:
.revealerContent {
  overflow: hidden;
  max-height: 0;

  // The animation is subject to jank because of the scollarea stretching or squashing below it.
  // Try to coax at least this part of the animation to use GPU acceleration:
  transform: translateZ(0);
  transition: max-height 0.25s;
}

// This class is added when user clicks Show Details button: ▽
.revealerShow {
  max-height: 4rem; // Enough to reveal the Textbox
}

.smallTitleTwo {
  padding: 1rem 1rem 0 1rem;

  .smallTitleSpace {
    margin: 0;
  }
}

.smallTitleOne {
  padding: 0.5rem 1rem 0.5rem 1rem;

  .smallTitleSpace {
    margin: 0;
  }
}
