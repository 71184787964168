@import "../../../../styles/constants/breakpoints";
@import "../../../../styles/constants/colors";
@import "../../../../styles/constants/variables";

.homePageSectionTwo {
  padding: 5.625rem 1.25rem;
  color: map-get($background-and-border-colors, background-dark-blue);
  font-family: $open-sans;

  @media #{$smMedia} {
    padding: 5rem 4rem;
  }

  .middleRowContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media #{$lgMedia} {
      max-width: 85rem;
      margin: auto;
    }
  }

  .sectionContainer {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;

    @media #{$mdMedia} {
      &:nth-child(even) {
        flex-direction: row-reverse;
      }

      &:not(:last-child) {
        margin-bottom: 1.25rem;
      }
    }

    .imageContainer {
      display: none;

      @media #{$mdMedia} {
        display: flex;
        width: 50%;
        justify-content: center;

        img {
          display: flex;
          width: 100%;
          max-width: 38rem;
          max-height: 25rem;
          justify-content: center;
        }
      }
    }

    .textContainer {
      display: flex;
      width: 50%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 5.625rem;

      @media #{$mdMedia} {
        width: 50%;
        margin-bottom: 0;
      }

      .iconContainer {
        margin: auto;

        @media #{$mdMedia} {
          margin: 0;
          margin-top: 0.25rem;
        }

        @media #{$lgMedia} {
          margin-top: 3.125rem;
        }

        img {
          height: 6.25rem;
        }
      }

      span {
        padding: 1.5rem 0 0.625rem;
        font-size: 1.25rem;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 1.5;
        text-align: center;

        @media #{$mdMedia} {
          font-size: 1.563rem;
          line-height: 1.25;
        }
      }

      p {
        font-size: 1.25rem;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.5;
        text-align: center;

        @media #{$mdMedia} {
          max-width: 25rem;
        }
      }
    }
  }
}
