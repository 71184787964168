@use "sass:map";
@import "~@oup/shared-front-end/dist/styles/breakpoints";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/fonts";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "../../../styles/constants/colors";

.outerEnterCodeModal {
  position: fixed;
  z-index: 102;
  display: flex;
  overflow: auto;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  padding: 0;
  margin: 0;
  background-color: transparent;
  inset: 0;
  opacity: 1;
  pointer-events: none;
  transition: 0.3s background-color, z-index ease-in-out;

  &.open {
    z-index: 99999999;
    /*
    This color is the only reason the "../../../styles/constants/colors"
    is imported, it doesn't exist in @oup/shared-front-end/styles/colors
    could be fixed to only use @oup/shared-front-end/styles/colors
    It is used throught the project.
    */
    background-color: $contrast-light-hover;
    pointer-events: unset;
    visibility: initial;
  }

  .modalContainer {
    position: absolute;
    top: -30%;
    left: 50%;
    max-width: 40rem;
    height: auto;
    border-radius: 0.3125rem;
    background-color: map.get($base, white100);
    transform: translate(-50%, -50%);
    transition: 0.3s top linear;

    &.animationFadeIn {
      top: 55%;
    }

    @media (max-width: $screen-width-840) {
      min-width: 20rem;
    }

    .modalHeader {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 1rem 1rem 0;

      button {
        min-height: unset;
        align-self: baseline;

        svg {
          margin-left: 0;
        }
      }

      .title {
        margin-bottom: 1rem;
        font-family: $font-family-dm-serif-display;
        font-size: 2rem;

        @media (max-width: $screen-width-840) {
          font-size: 1.5rem;
        }
      }
    }

    .modalContent {
      .title {
        margin: 0 6.25rem 1rem 0;
        font-size: 2rem;
        font-weight: 900;

        @media (max-width: $screen-width-840) {
          font-size: 1.5rem;
        }
      }

      .subtitle {
        padding: 1.5rem;
        border-top: 0.063rem solid map.get($primary, primary10);

        span {
          font-weight: 900;
        }
      }

    }

    .modalFooter {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      padding: 1.5rem;
    }
  }
}

.radioButtonGroupContainer {
  display: flex;
  flex-flow: column;
  padding: 0 $gap-4;
  gap: $gap-3;
}

.radioButtonIcons {
  width: $icon-size-regular;
  height: $icon-size-regular;
}
