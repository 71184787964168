@use "sass:map";
@import "~@oup/shared-front-end/dist/styles/breakpoints";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";

.helpButtonLarge {
  display: flex;

  @media (max-width: $screen-width-840) {
    display: none;
  }
}

.helpButtonSmall {
  display: flex;

  @media (min-width: $screen-width-840) {
    display: none;
  }
}

.helpIconLoggedOut {
  fill: map.get($primary, primary100);
}
