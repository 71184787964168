@import '../../styles/constants/breakpoints';

.gradebookContainer {
  position: relative;
  padding: 24px;

  @media #{$phoneMedia} {
    padding: 24px 0;
  }
}

.infoIcon {
  fill: red !important;
}

.gradebookUiUpdates {
  padding: 0;
}
