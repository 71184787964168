@use "sass:map";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "@oup/shared-front-end/dist/styles/themes/base/typography";
@import "@oup/shared-front-end/dist/styles/themes/base/sizes";

@import "../../../styles/constants/breakpoints";
@import "../../../styles/mixins/hub/hub-button-focus";

.stepContainer {
  display: flex;
  flex-direction: column;
  padding: $gap-6 $gap-5 $gap-6 $gap-5;
  gap: $gap-6;
  max-width: 40.5rem;
}

.heading {
  font-family: $font-family-display-font;
  padding: 0;
}

.addStudentsContainer {
  .noteContainer {
    margin: 0.938rem 0 1.25rem;

    .noteText {
      font-size: 1em;
      line-height: 1.25rem;
    }
  }

  .selectionContainer {
    .addNotificationInputsBtn {
      padding: 0.313rem 1.25rem;
      border: 1px solid map.get($primary, primary60);
      border-bottom: 2px solid map.get($primary, primary60);
      margin-top: 1.25rem;
      color: map.get($primary, primary100);

      &:hover {
        color: map.get($primary, primary90);
      }

      &:focus {
        @include standart-focus-button;
      }
    }
  }
}

.enrollStudent {
    height: 100%;

    h2 {
      font-weight: $font-weight-700;
    }

    h2, p {
      color: map.get($primary, primary100);
      font-family: $font-family-text-font;
    }
}


@media #{$xsMedia} {
  .addStudentsContainer {
    width: 100%;
  }
}
