@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints";

$mobileContainerExterior: 9.5rem;
$xsMediaContainerExterior: 25rem;

@keyframes stretch {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

.classTest {
  padding: 0.75rem;

  &:hover,
  &:focus {
    background-color: map-get($secondary-colors, hub_light_grey);
    border-radius: 5px;
    cursor: pointer;

    .topInfo .textTitle {
      color: map-get($hubColors, hub_link_hover);
    }
  }

  &:focus {
    box-shadow: inset 0 0 0 1px $color-white, inset 0 0 0 2px map-get($primary-colors, primary), inset 0 0 0 3px $color-white;
    outline: none;
  }

  .infoLabelContainer {
    margin-left: 3rem;

    @media #{$phoneMedia} {
      margin-left: 2.5rem;
    }
  }
}

.testContainer {
  position: relative;
  display: flex;
  align-items: center;

  .testInfoContainer {
    display: flex;
    align-items: center;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .imgContainer {
      display: flex;
      min-width: 2.425rem;
      height: 3.125rem;
      align-items: center;
      justify-content: center;
      background-color: color(primary-dark);
      border-radius: 5px;

      svg {
        width: 60%;
        height: 70%;
        fill: $color-white;
        opacity: 0.5;
      }

      @media #{$phoneMedia} {
        min-width: 2rem;
        height: 2.5rem;
      }
    }

    .mainInfoContainer {
      margin-left: 0.75rem;

      @media #{$xsMedia} {
        max-width: calc(#{$viewportWidth} - #{$xsMediaContainerExterior});
      }

      @media #{$phoneMedia} {
        max-width: calc(#{$viewportWidth} - #{$mobileContainerExterior});
      }
    }

    .topInfo {
      display: flex;
      flex-direction: row;
      line-height: 1.25rem;

      .statusContainer {
        display: flex;
        height: 1.25rem;
        padding: 0 0.625rem;
        border: 1px solid;
        margin: auto;
        border-radius: 11px;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
      }

      .textTitle {
        margin-left: 7px;
        color: map-get($hubColors, hub_link);

        @media #{$xsMedia} {
          white-space: nowrap;
        }
      }
    }

    .dateContainer {
      margin-top: 0.5rem;

      p {
        margin: 0;
        font-size: 13px;

        span:first-of-type {
          font-weight: 600;
        }
      }

      @media #{$xsMedia} {
        p span {
          white-space: nowrap;
        }
      }
    }
  }

  .dropdownMenu {
    .classTestDropdown button:not(:focus) {
      box-shadow: none;
    }
  }

  &.active {
    .dropdownMenu {
      margin-left: auto;
    }

    .testInfoContainer .topInfo .statusContainer {
      border-color: map-get($hubColors, blue-color-primary);

      .pulse {
        display: block;
        width: 12px;
        height: 12px;
        margin-top: 3px;
        margin-right: 4px;
        animation-delay: 0;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-name: stretch;
        animation-play-state: running;
        animation-timing-function: ease-out;
        background-color: map-get($hubColors, blue-color-primary);
        border-radius: 100%;
      }
    }
  }

  &.upcoming {
    .dropdownMenu {
      margin-left: auto;
    }

    .testInfoContainer .topInfo .statusContainer {
      // stylelint-disable value-keyword-case
      border-color: map-get($legacy-color-names, OK);

      .pulse {
        display: none;
      }
    }
  }

  &.completed {
    .dropdownMenu {
      margin-left: auto;
    }

    .testInfoContainer {
      width: 70%;

      .mainInfoContainer {
        @media #{$xsMedia} {
          max-width: calc(#{$viewportWidth} - 30.5rem);
        }

        @media #{$phoneMedia} {
          max-width: calc(#{$viewportWidth} - 12rem);
        }
      }

      .topInfo .statusContainer {
        border-color: map-get($legacy-color-names, good);

        .pulse {
          display: none;
        }
      }
    }

    .scoreContainer {
      width: 15%;
      font-size: 1rem;
      text-align: center;

      span:first-child {
        font-size: 2rem;
      }
    }
  }
}
