@import "../../styles/constants/colors";
@import '../../styles/constants/breakpoints';

.headersEmbedded header,
.headersEmbedded nav {
  display: none;
}

.banners {
  background-color: map-get($legacy-color-names, tooltip);

  .systemNotification {
    background-color: map-get($background-and-border-colors, background-dark-blue);
  }

  > *:nth-child(2) {
    border-top: 1px solid $contrast-dark-accent-02;
  }
}
