@import '../../styles/constants/breakpoints.scss';
@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";
@import "~@oup/shared-front-end/dist/styles/breakpoints";

.wizardContainer {
  position: fixed;
  z-index: 104;
  top: 0;
  left: 0;
  width: 100vw;
  margin: 0;
  background-color: map-get($background-and-border-colors, background);
}

.header {
  width: 100%;
  padding: 1.5rem 3.75rem 1rem 3.75rem;
  background-color: map-get($background-and-border-colors, background);

  .topContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    flex: 1;
    font-size: 1rem;

    span {
      font-weight: bold;
    }
  }

  .helpAndSupport {
    display: flex;
    height: 100%;
    margin: auto 0;
    margin-left: auto;
    color: map-get($hubColors, hub_link);

    a {
      display: inline-flex;
    }

    svg {
      width: 1.25rem;
      height: 1.25rem;
      fill: map-get($hubColors, hub_link);
    }

    .helpSupportText {
      display: block;
      padding-left: 0.625rem;
      line-height: 1.25rem;

      @media #{$phoneMedia} {
        display: none;
      }
    }
  }

  .closeButton {
    margin-left: 1.875rem;
  }

  .progressContainer {
    padding: 1rem 0;
  }

  @media #{$smTabletMedia} {
    padding: 1.5rem;
  }
}

.step {
  width: 100%;
  height: calc(100vh - 10.625rem);
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 5rem 2.5rem;

  @media screen and (max-width: $screen-width-1024) {
    height: calc(100vh - 3rem);
  }

  @media screen and (max-width: $screen-width-840) {
    height: calc(100vh - 10.625rem);
    align-items: center;
    flex-direction: column;
    padding: 5rem 1.5rem;
  }

  .stepContent {
    max-width: 62.5rem;
    padding: 5rem 3.75rem;
    margin: 0 auto;

    @media #{$smTabletMedia} {
      max-width: 100%;
      padding: 0 1.5rem 9.75rem 1.5rem;
    }
  }

  h1 {
    padding: 0;
    margin: 0 0 2rem;
    line-height: 1.2;
  }

  .imageContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    max-width: 30%;
    justify-content: center;
    margin-top: 1.5rem;
    margin-bottom: 9rem;

    img {
      width: 100%;
    }

    @media #{$xsMedia} {
      width: 100%;

      img {
        max-width: 18.75rem;
        max-height: 18.75rem;
      }
    }

    @media #{$xsMedia} {
      margin-bottom: 1rem;
    }
  }
}

.contentCentered {
  max-width: 37.5rem;
  margin: 0 auto;
}

.footer,
.footerRightJustify {
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100vw;
  height: 5.625rem;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 3.75rem;
  border-top: 1px solid map-get($background-and-border-colors, border);
  background: map-get($background-and-border-colors, background);

  .cancelLink {
    padding: 0.563rem;
    color: map-get($hubColors, hub_link);

    &:hover {
      color: map-get($hubColors, hub_dark_blue);
    }

    &:focus {
      @include standart-focus-button();
    }
  }

  .tooltip {
    top: 0.25rem;
    margin-right: auto;
    margin-left: 0.625rem;

    span {
      top: -7.25rem;
      left: -2.813rem;
      width: 9.688rem;
      text-align: left;

      &::after {
        bottom: -0.5rem;
        left: 80%;
        transform: rotate(180deg);
      }
    }
  }

  @media #{$smTabletMedia} {
    padding: 1.5rem;
  }
}

.footerRightJustify {
  justify-content: flex-end;
}

.flexRight {
  margin-left: auto;
}

.infoContainer {
  margin-top: 0.313rem;

  span {
    color: map-get($typography-colors, sub-text);
  }

  .boldText {
    margin: 0 0.313rem;
    font-weight: 700;
  }

  .link {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin-left: 0.313rem;
    color: map-get($hubColors, hub_link);
    font-size: 1rem;
    text-decoration: underline;
  }

  .linkGlyph {
    width: 1.3rem;
    height: 1.3rem;
    fill: map-get($hubColors, hub_link);
  }

  .link:hover {
    color: map-get($hubColors, hub_link_hover);

    .linkGlyph {
      fill: map-get($hubColors, hub_link_hover);
    }
  }
}

.joiningCodeContent {
  display: flex;
  align-items: center;

  .joiningCodeLabel {
    margin-right: 0.313rem;
  }

  .joiningCodeOrgId {
    font-weight: bold;
  }

  .joinCodeTooltip {
    top: 0.125rem;
    margin-left: auto;

    span {
      left: 0.625rem;
      width: 15.625rem;
      text-align: left;
    }

    @media #{$smTabletMedia} {
      span {
        left: -5.938rem;

        &::after {
          bottom: 100%;
          left: 92%;
        }
      }
    }
  }
}

.infoIcon {
  width: 1.5rem;
  height: 1.5rem;
}

.validationFeedback {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  margin: 1.25rem 0;
  background-color: map-get($hubColors, onboarding_wizard_valid_input_background_color);
  border-radius: 5px;
  color: map-get($primary-colors, primary);
  font-size: 0.875rem;
  font-weight: 600;
  gap: 0.625rem;

  .infoIcon {
    width: 2rem;
    height: 2rem;
  }
}

.summaryTable {
  table {
    width: 100%;
    padding: 0.313rem;
    border: 1px solid map-get($hubColors, onboarding_wizard_valid_input_background_color);
    margin-bottom: 0.313rem;
    border-collapse: collapse;
    border-radius: 5px;
  }

  tr {
    &:not(:first-of-type) {
      border-top: 1px solid map-get($hubColors, onboarding_wizard_valid_input_background_color);
    }

    td {
      padding: 0.75rem 1rem;

      &:first-of-type {
        width: 50%;
        padding-right: 1.25rem;
        font-size: 0.875rem;
        font-weight: bold;
      }

      span {
        display: block;
      }
    }
  }
}


.studentsAndLicencesContainer {
  display: flex;
  align-items: center;
  padding: 0.625rem 0;
  flex: 2;
  font-size: 1rem;
  gap: 0.625rem;
  .studentsAndLicencesTitle {
    font-weight: 600;
    color: map-get($primary, primary100);
    margin: 0;
  }
  p + p {
    margin-top: 0;
  }
  .studentsIcon {
    height: 1.5rem;
    width: 1.5rem;
  }
  .licencesIcon {
    height: 0.313rem;
    width: 0.313rem;
  }
}
