@import "../../styles/constants/breakpoints";
$minColWidth: 220px;
$resourceMinWidth: 140px;
$maxColWidth: 1fr;

.hubProductLayout {
  display: flex;
  height: calc(100% - 3.75rem);
  align-items: flex-start;

  .hubProductLayoutSubSidenavbar {
    height: 100%;

    @media #{$phoneMedia} {
      width: 100%;
      padding: 12px;
      margin: 0;
    }
  }

  .hubProductChildrenContainer {
    width: 100%;
    padding: 24px;

    @media #{$smMedia} {
      padding: 16px;
    }

    @media #{$smMdMedia} {
      padding: 24px;
    }
  }
}
