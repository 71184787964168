@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints.scss";

.outerModal {
  position: fixed;
  z-index: 14;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  background-color: $color-white;

  @media screen and (max-device-width: #{$smMdBreakpoint}) and (orientation: landscape) {
    position: relative;
  }

  @media #{$phoneMedia} {
    position: relative;
  }

  .innerModal {
    max-width: 90%;
    transform: translateY(5rem);

    @media #{$phoneMedia} {
      height: auto;
      padding-bottom: 8rem;
      overflow-y: scroll;
    }

    @media screen and (max-device-width: #{$smMdBreakpoint}) and (orientation: landscape) {
      padding-bottom: 6rem;
    }

    .textContainer {
      text-align: center;
    }

    .itemsContainer {
      margin-top: 1.5rem;

      ul {
        display: flex;
        align-items: center;
        justify-content: center;

        @media #{$phoneMedia} {
          flex-direction: column;
        }
      }
    }
  }
}
