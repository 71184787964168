@import "../../../../styles/constants/breakpoints";
@import "../../../../styles/constants/colors";
@import "../../../../styles/constants/variables";

.sectionOneGrid {
  display: flex;
  max-width: 78rem;
  flex-direction: column;
  padding: 0.625rem;
  margin: auto;
  background-color: map-get($hubColors, hub_neutral_blue);
  color: map-get($background-and-border-colors, background-dark-blue);
  font-family: $open-sans;

  @media #{$smMedia} {
    flex-direction: row;
    padding: 1.25rem 1.25rem 0;
  }

  @media #{$lgMedia} {
    max-width: 150rem;
  }
}

.bannerContainer {
  @media #{$smMedia} {
    width: 60%;
  }
}

.bannerText {
  display: block;
  margin-top: 3rem;
  text-align: center;

  h1,
  p {
    max-width: 100%;
  }

  h1 {
    margin-top: 3rem;
    font-family: "DM Serif Display", serif;
    font-size: 2.438rem;
    font-weight: 400;
    letter-spacing: 0.125rem;
    line-height: 1.1;

    @media #{$smMedia} {
      margin: 2.5rem 0 1.25rem;
      font-size: 2.9rem;
    }

    @media #{$mdMedia} {
      margin: 0 0 0.75rem;
      font-size: 3.75rem;
    }

    @media #{$lgMedia} {
      font-size: 4.768rem;
    }
  }

  p {
    margin-bottom: 1.875rem;
    font-size: 1.25rem;
    font-weight: 300;
    letter-spacing: 0.1875rem;
    line-height: 1.1;

    @media #{$smMedia} {
      margin-bottom: 2.5rem;
      font-size: 1.6rem;
    }

    @media #{$mdMedia} {
      font-size: 2.5rem;
    }

    @media #{$lgMedia} {
      font-size: 3rem;
    }

    @media #{$phoneMedia} {
      font-size: 1.563rem;
      line-height: 1.25;
    }
  }

  // Sign in button
  button {
    height: 4.5rem;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    background-clip: padding-box;
    background-color: map-get($hubColors, hub_madrid_orange);
    color: map-get($background-and-border-colors, background-dark-blue);
    font-size: 1.25rem;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1.5rem;

    @media #{$mdMedia} {
      height: 4.5rem;
      margin-bottom: 2rem;
      font-size: 1.563rem;
      line-height: 1.25rem;
    }

    &:hover {
      background-color: map-get($hubColors, hub_madrid_orange_dark);
      box-shadow: none;
    }

    &:focus {
      border-color: map-get($primary-colors, primary);
      box-shadow:
        0 0 0 1px $color-white,
        inset 0 0 0 2px map-get($primary-colors, primary),
        inset 0 0 0 3px $color-white;
    }
  }

  @media #{$smMedia} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  @media #{$mdMedia} {
    padding: 0 0 0 2.75rem;
    margin-top: 5.25rem;
    text-align: left;
  }
}

.primaryImgContainer {
  position: relative;
  max-height: 33.125rem;
  margin-bottom: 0.875rem;
  font-size: 0;
  text-align: center;

  img {
    position: relative;
    z-index: 2;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media #{$phoneMedia} {
    display: flex;
  }

  @media #{$smMedia} {
    display: flex;
    width: 40%;
  }

  @media #{$mdMedia} {
    display: block;
  }
}
