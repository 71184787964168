@import "../../styles/constants/colors";
@import '../../styles/constants/breakpoints';

$headerHeight: 77px;
$footerHeight: 50px;

.hubApp {
  .pageContainer {
    display: flex;
    min-height: calc(var(--vh100, 100vh) - #{$headerHeight});
    flex-direction: column;

    main div.srOnly {
      position: absolute;
      overflow: hidden;
      width: 1px;
      height: 1px;
      margin: 0;
      opacity: 0;
      pointer-events: none;

      h1 {
        color: map-get($legacy-color-names, tooltip);
        font-size: 1rem;
        line-height: 1rem;
      }
    }

    footer {
      margin-top: auto;
    }

    .loadingSpinnerWrapper {
      display: flex;
      height: calc(var(--vh100, 100vh) - #{$headerHeight} - #{$footerHeight});
      min-height: calc(var(--vh100, 100vh) - #{$headerHeight} - #{$footerHeight});
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
