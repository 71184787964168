@import "../../styles/constants/colors";

.resourceOuterModal {
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  background-color: transparent;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s opacity ease-in-out;

  &.open {
    z-index: 9999999;
    background-color: map-get($secondary-colors, hub_dashboard);
    opacity: 1;
    pointer-events: unset;
    transition: 0.2s opacity ease-in-out;
  }

  .resourceModalContent {
    .resourceModalPreviewable {
      background-color: map-get($secondary-colors, hub_dashboard);

      .resourceModalIframe {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        box-shadow: none;
        outline: none;
      }
    }
  }
}
