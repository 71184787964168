@import "../../../styles/constants/colors";
@import "../../../styles/constants/breakpoints";
@import "../../../styles/mixins/hub/hub-button-focus";

.classSubMenu {
  min-width: 13.75rem;
  max-width: 18.75rem;
  height: 100%;
  min-height: calc(100vh - 11.8525rem); //right border expansion
  padding: 1.5rem;
  border-right: 1px solid $color-light-grey;

  li a {
    line-height: 1.5rem;
  }

  .classManagedItem {
    border-top: 1px solid $color-light-grey;
  }

  .classSubMenuItem {
    position: relative;
    display: flex;
    width: 100%;
    min-height: 3.125rem;
    align-items: center;
    border-left: 5px solid $color-black-rgba;
    color: map-get($hubColors, hub_link);
    font-weight: 400;
    text-align: unset;

    .buttonName {
      position: relative;
      width: 100%;
      padding: 0.8125rem 1rem 0.8125rem 0.6875rem;
      cursor: pointer;
    }

    &:focus:not(.classSubMenuItemActive) {
      @include submenu-button-on-focus();
    }

    &:hover:not(.classSubMenuItemActive) {
      border-left: 5px solid map-get($secondary-colors, hub_light_grey);
      background-color: map-get($secondary-colors, hub_light_grey);
      color: map-get($hubColors, blue-color-tertiary);
    }

    &.classSubMenuItemActive {
      border-left: 5px solid map-get($secondary-colors, hub_blue);
      color: map-get($secondary-dark-colors, archived-dark);
      font-weight: 600;

      &:focus {
        @include no-padding-button-on-focus();
      }
    }
  }

  .manageIcon {
    width: 1.56rem;
    height: 1.56rem;
  }
}

@media #{$phoneMedia} {
  .classSubMenu {
    width: 100%;
    min-width: unset;
    max-width: unset;
    padding: unset;
    border: none;
  }
}
