@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";

.classroom {
  position: relative;
  display: grid;
  width: 100%;
  min-width: 13.75rem;
  max-width: 18.75rem;
  box-sizing: border-box;
  border: 1px solid $color-light-grey;
  margin-bottom: 1px;
  border-radius: 5px;
  box-shadow: 0 0 0 1px $color-black-rgba;
  cursor: pointer;
  grid-template-rows: auto 1fr;

  .imgContainer {
    position: relative;
    display: inherit;
    overflow: hidden;
    height: 7.5rem;
    background-color: map-get($secondary-colors, classroom_background);
    border-radius: 5px 5px 0 0;
    box-shadow: 0 0 5px 0 $contrast-light-accent-50;

    .svgContainer {
      position: absolute;
      top: 50%;
      display: flex;
      justify-content: center;
      margin: 0 1.875rem;
      background-color: map-get($secondary-colors, classroom);
      border-radius: 100%;
      transform: translateY(-50%);

      svg {
        width: 100%;
        height: 100%;
        padding: 20%;
        margin: auto;
        fill: white;
      }
    }
  }

  .classroomDetails {
    z-index: 1;
    display: flex;
    flex-direction: column;
  }

  .textContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0.75rem;
    word-break: break-all;

    .classroomName {
      position: relative;
      font-weight: 600;
    }

    div {
      justify-content: space-between;
    }
  }

  &:hover {
    border: solid 1px map-get($hubColors, blue-color-primary);
    background-color: $color-black-rgba;
    box-shadow: 0 0 0 1px map-get($hubColors, blue-color-primary);
    mix-blend-mode: normal;
    text-decoration: none;

    .imgContainer {
      filter: brightness(70%);
    }
  }

  &:focus {
    @include tile-on-focus();

    .imgContainer {
      filter: brightness(70%);
    }
  }

  .dropDownMenu {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

@media #{$phoneMedia} {
  .classroom {
    max-width: none;
    grid-gap: 0.5rem;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;

    &:hover {
      border-color: $color-light-grey;
      box-shadow: none;
    }

    &:hover svg {
      filter: brightness(100%);
    }

    .classroomDetails {
      margin-right: 0.5rem;
    }

    .imgContainer {
      width: 6.25rem;
      height: 6.25rem;
      border: 1px solid $contrast-light-accent-50;
      margin: 0.5rem;
      background-color: unset;
      border-radius: 100%;

      .svgContainer {
        margin: 0;
      }

      svg {
        width: 100%;
        height: 100%;
        padding: unset;
        border-radius: 5px;
        object-fit: fill;
        object-position: 50% 0;
      }
    }

    .textContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0.75rem 1rem 0.75rem 0;

      span {
        // Allow space for the dropdownMenu
        width: 90%;
      }
    }
  }
}
