@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints";
@import '../../styles/constants/variables';
@import "../../styles/mixins/hub/hub-button-focus";

.siteFooter {
  position: relative;
  background-color: map-get($background-and-border-colors, background);
  z-index: 15;

  @media #{$phoneMedia} {
    border-top: 1px solid $color-borders;
  }

  @media screen and (max-device-width: #{$lgBreakpoint}) and (orientation: landscape) {
    border-top: 0 none;
    background-color: transparent;
  }

  .topSection {
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 1px solid $color-borders;

    @media #{$phoneMedia} {
      border-top: none;
    }

    @media #{$smMedia} {
      padding-bottom: 60px;
    }
  }

  font-size: 14px;

  a {
    color: $color-primary-blue;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background-color: transparent;
      color: $color-primary-blue-dark;
      mix-blend-mode: normal;
      text-decoration: underline;
    }
  }

  a,
  .copy {
    color: map-get($hubColors, dark-gray);
  }

  .linkList {
    text-align: center;

    @media #{$smMedia} {
      text-align: left;
    }

    h4 {
      margin-top: 8px;
      margin-bottom: 4px;
      font-weight: 600;

      @media #{$smMedia} {
        margin-top: 0;
        margin-bottom: 11px;
      }
    }

    li {
      display: inline-block;
      margin: 0 8px;

      @media #{$smMedia} {
        display: block;
        margin: 0 0 7px 0;
      }
    }

    >div {
      margin: 0.8em 0 0.3em 0;
      font-size: 1rem;
      font-weight: 600;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @media #{$smMedia} {
        display: block;
        flex-wrap: initial;
        justify-content: initial;
      }
    }
  }

  .logoContainer {
    align-items: center;
    justify-content: space-between;

    svg {
      width: 150px;
      height: 44px;
      margin-top: 10px;
    }

    .footer_text {
      color: map-get($typography-colors, text);
    }

    p {
      display: block;
      max-width: 400px;
      margin: 16px auto;
      color: map-get($hubColors, dark-gray);
      font-size: 14px;
      text-align: center;

      @media #{$smMedia} {
        display: initial;
        width: 100%;
        max-width: initial;
        max-width: 300px;
        margin: initial;
        margin-bottom: 10px;
        text-align: right;
      }
    }

    @media #{$smMedia} {
      align-items: flex-end;

      svg {
        width: 110px;
        height: 32px;
      }

      p {
        display: block;
      }
    }
  }

  .socialStrip {
    border-bottom: 1px solid $color-borders;

    a {
      display: flex;
      width: 60px;
      height: 50px;
      align-items: center;
      justify-content: center;

      svg {
        width: 25px;
        height: 25px;
      }

      @media #{$smMedia} {
        width: calc((60px / 2) + 60px);
        height: 60px;

        svg {
          width: 30px;
          height: 30px;
        }
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  li a:focus {
    @include no-padding-button-on-focus();

    outline: none;
  }

  .socialStrip a:hover {
    background-color: $color-very-light-grey;
  }

  .legalStrip {
    padding: 15px 0;
    border-top: 1px solid $color-borders;

    @media screen and (max-device-width: #{$smMdBreakpoint}) and (orientation: landscape) {
      border-top: 1px solid $color-borders;
      background-color: map-get($background-and-border-colors, background);
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @media #{$smMedia} {
        justify-content: flex-start;
      }
    }

    li:not(:last-of-type) {
      margin: 0 8px 8px;

      @media #{$smMedia} {
        margin: 0 15px 0 0;
      }
    }

    .copy {
      width: 100%;
      flex-basis: 100%;
      padding-top: 8px;
      border-top: 1px solid $color-borders;
      text-align: center;

      @media #{$smMedia} {
        width: auto;
        flex-basis: auto;
        padding-top: 0;
        border-top: none;
        margin-left: auto;
      }
    }
  }

  &.footerRestyling {
    font-family: $open-sans;
    font-size: 0.875rem;
    font-weight: 400;

    .topSection {
      padding-top: 30px;
      padding-bottom: 30px;
      border-top: 1px solid $color-borders;

      @media #{$phoneMedia} {
        padding-top: 15px;
        padding-bottom: 0;
        border-top: none;
      }

      @media #{$smMedia} {
        padding-bottom: 60px;
      }

      a {
        color: map-get($typography-colors, text);
      }
    }

    .logoContainer {
      svg {
        width: 181px;
        height: 52px;

        @media #{$phoneMedia} {
          margin-bottom: 1.25rem;
        }
      }
    }

    .legalStrip {
      border-top: 1px solid $color-borders;

      @media #{$phoneMedia} {
        border-top: none;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @media #{$smMedia} {
          justify-content: flex-start;
        }
      }

      a {
        color: map-get($typography-colors, sub-text);

        &:hover,
        &:focus {
          color: map-get($hubColors, hub_link_hover);
        }

        &:focus {
          @include no-padding-button-on-focus();

          border-radius: 2px;
          outline: none;
        }
      }

      .copy {
        border-top: 1px solid map-get($background-and-border-colors, border-grey);
        color: map-get($typography-colors, sub-text);

        @media #{$smMedia} {
          border-top: none;
        }
      }
    }

    &.darkBackground {
      .topSection {
        background-color: map-get($background-and-border-colors, background-dark-grey);
      }

      .legalStrip {
        background-color: map-get($background-and-border-colors, background-dark-blue);

        a {
          color: $white-grey;

          &:hover {
            color: $color-white;
          }
        }

        .copy {
          color: $white-grey;
        }
      }
    }
  }
}

.fixed {
  @media screen and (max-device-width: #{$smMdBreakpoint}) and (orientation: landscape) {
    height: 50px;

    .legalStrip {
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: map-get($background-and-border-colors, background);
    }
  }

  @media screen and (max-device-width: #{$smBreakpoint}) and (orientation: landscape) {
    height: 87px;
  }
}

.Recaptcha {
  text-align: center;
  padding-bottom: 16px;
  color: map-get($hubColors, dark-gray);
  background-color: map-get($background-and-border-colors, background-dark-grey);
  font-size: 11px;
  font-weight: 400;
  letter-spacing: -0.017em;
  line-height: 150%;

  a {
    color: map-get($hubColors, dark-gray);
    border-bottom: 1px solid lighten(map-get($legacy-color-names, tooltip), 50%);
    font-weight: 600;

    &:hover {
      border-bottom: 2px solid map-get($hubColors, hub_link);
      color: map-get($hubColors, hub_link);
      text-decoration: none;
    }
  }
}
