@import "../../../styles/mixins/hub/hub-button-focus";
@import "../../../styles/constants/colors";

.assessmentFolderPage {
  .labelTitleFolder {
    margin: 1.3rem 0.1rem;
    font-size: 21px;
    font-weight: 600;
  }

  .backButton {
    a {
      :not([disabled]):hover {
        text-decoration: none !important;
      }
    }

    a:focus {
      border-radius: 3px;
      box-shadow: 0 0 0 1px $color-white, 0 0 0 3px map-get($primary-colors, primary), 0 0 0 4px $color-white;
    }
  }
}

.assessmentTestFoldersList {
  margin-top: 12px;
}
