@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints";
@import "../../styles/mixins/hub/hub-button-focus";

.testOuterModal {
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  background-color: transparent;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s opacity ease-in-out;

  &.open {
    z-index: 9999999;
    background-color: map-get($secondary-colors, hub_dashboard);
    opacity: 1;
    pointer-events: unset;
    transition: 0.2s opacity ease-in-out;
  }

  .testModalHeader {
    position: absolute;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1.5rem;
    border-bottom: 1px solid map-get($secondary-colors, hub_dashboard_selected);
    background-color: map-get($secondary-colors, hub_dashboard);

    .closeButtonContainer {
      text-align: right;

      button:focus {
        @include standart-focus-button();
      }
    }

    @media #{$phoneMedia} {
      padding: 1rem 1.5rem;
    }
  }

  .testModalContent {
    .testModal {
      background-color: map-get($secondary-colors, hub_dashboard);

      .testModalIframe {
        position: absolute;
        z-index: -1;
        top: 89px;
        left: 0;
        width: 100%;
        height: calc(100vh - 90px);
        border: none;
        box-shadow: none;
        outline: none;

        // fixing issues with the footer navigation buttons on some mobile screens - EPS-10092
        @media #{$phoneMedia} {
          top: 75px;
          height: calc(100vh - 140px);
        }

        // fixing issues with the footer navigation buttons on some tablet screens (e.g.: iPad) - EPS-10353
        @media #{$tabletMedia} {
          top: 75px;
          height: calc(100vh - 140px);
        }
      }
    }
  }

  .testModalFooter {
    position: absolute;
    z-index: 200;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    border-top: 1px solid map-get($secondary-colors, hub_dashboard_selected);
    background-color: map-get($secondary-colors, hub_dashboard);

    @media #{$phoneMedia} {
      flex-direction: column;
      padding: 1rem 1.5rem;
    }

    .testModalFooterInfo {
      color: $color-white;

      p {
        font-size: 1.3125rem;
        font-weight: 600;
      }

      p:nth-child(2) {
        margin: 0;
        font-size: 1rem;
        font-weight: 300;
      }

      span {
        font-size: 1rem;
      }

      @media #{$phoneMedia} {
        margin: 0 0 0.75rem 0;
        text-align: center;
      }
    }
  }

  .loading {
    color: $color-white;
    text-align: center;

    h1 {
      margin: 0;
    }

    p {
      margin: 0;
      font-size: 1.3rem;
    }

    .loaderSvg {
      width: 5rem;
      margin: 0 auto;
    }
  }

  .resourceModalNonPreviewable {
    h1 {
      font-size: 34px;

      @media #{$phoneMedia} {
        font-size: 21px;
      }
    }
  }
}
