@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";

[data-list="tile"] {
  display: grid;

  .course {
    display: grid;
    overflow: hidden;
    width: 100%;
    min-width: 13.75rem;
    max-width: 18.75rem;
    box-sizing: border-box;
    border: 1px solid $color-light-grey;
    margin-bottom: 1px;
    border-radius: 5px;
    box-shadow: 0 0 0 1px $color-black-rgba;
    cursor: pointer;
    grid-template-rows: auto 1fr;

    .imgContainer {
      position: relative;
      display: inherit;
      height: 7.5rem;
      box-shadow: 0 0 5px 0 $contrast-light-accent-50;

      img {
        position: absolute;
        top: 50%;
        width: 100%;
        height: auto;
        padding: 0 1.875rem;
        margin: auto;
        transform: translateY(-50%);
      }
    }

    .textContainer {
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0.75rem;
      background-color: white;

      span {
        position: relative;
        font-weight: 600;
      }

      div {
        justify-content: space-between;
      }

      .labelsContainer {
        display: block;
        margin-bottom: 0.35rem;
      }

      .comingSoonLabel {
        background-color: map-get($primary-colors, primary);
      }

      .newContentlabelPill {
        background-color: map-get($hubColors, hub_summer_yellow);
        width: max-content;
        margin-bottom: 1rem;
        padding: 0px 8px 0px 8px;
        gap: 10px;
        border-radius: 10px;
        opacity: 0px;
        span {
          color: map-get($primary-colors, primary);
          font-size: 13px;
          font-weight: 400;
          line-height: 19.5px;
          letter-spacing: -0.017em;
          text-align: left;
        }
      }
    }

    &:hover {
      border: solid 1px map-get($hubColors, blue-color-primary);
      box-shadow: 0 0 0 1px map-get($hubColors, blue-color-primary);

      .imgContainer {
        filter: brightness(70%);
      }
    }

    &:focus {
      @include tile-on-focus();

      .imgContainer {
        filter: brightness(70%);
      }
    }
  }

  @media #{$phoneMedia} {
    .course {
      max-width: none;
      grid-gap: 0.5rem;
      grid-template-columns: auto 1fr;
      grid-template-rows: 1fr;

      &:hover {
        border-color: $color-light-grey;
        box-shadow: none;
      }

      &:hover img {
        filter: brightness(100%);
      }

      .imgContainer {
        width: 6.25rem;
        height: 6.25rem;
        border: 1px solid $contrast-light-accent-50;
        margin: 0.5rem;
        border-radius: 100%;

        img {
          width: 100%;
          height: 100%;
          padding: unset;
          border-radius: 5px;
          object-fit: fill;
          object-position: 50% 0;
        }
      }

      .textContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0.75rem 1rem 0.75rem 0;
      }
    }
  }
}
